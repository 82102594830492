.v-align {

	display: table;
	height:100%;
	
	> div {
		z-index: 1;
		display: table-cell;
		vertical-align: middle;
	}
}

.gl-0 {
	padding-left:0 !important;
}
.gr-0 {
	padding-right:0 !important;
}


#modal_contact {
	
	.modal-content {
		
		text-align:center;
		padding:50px 20px;
		background:transparent;
		
		.container > .row > div {
			aspect-ratio:1/1;
			
			@media only screen and (max-width: 990px) {
				aspect-ratio:inherit;
			}
		}
		
		.titre {
			color:white;
			font-size:28px;
			text-transform:uppercase;
			font-weight:700;
			margin-bottom:20px;
		}
		a {
			font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
			color:black;
			font-size:36px;
			font-weight:700;
			text-decoration:none;
			line-height:38px;
			position:relative;
			background: url(img/inscription_off.jpg) right bottom;
			background-size:cover;
			position:relative;
			width:120%;
			
			@media only screen and (max-width: 990px) {
				width:100%;
				padding-top:50px;
				padding-bottom:80px;
			}
			
			.inscription {
				font-family: 'Montserrat', sans-serif;
				position:absolute;
				bottom:20px;
				right:20px;
				color:white;
				font-weight:bold;
				display:block;
				font-size:16px;
				padding: 0 48px 0 10px;
				background:url('img/fleche_blanc.png') no-repeat $orange;
				background-position:right 5px top 50%;
				background-size:30px;
				cursor:pointer;
			}
			
		}
		a:hover {
			background: url(img/inscription_on.jpg) right bottom;
			background-size:cover;
			color:white;
		}
		
	}
}

/* HOME */

body.home {
	
	.site-content {
	
		@media only screen and (max-width: 990px) {
			background:url('img/page_bckgrd.jpg') center 0;
		}
		
		#home-top {
			
			background:url('img/header_bckgrd.jpg') center 0 no-repeat;
			
			
			@media only screen and (max-width: 990px) {
				background:transparent;
			}
			
			#visuel-home {
				
				height:651px;
				
				@media only screen and (max-width: 990px) {
					height:auto;
					aspect-ratio : 1/0.355 !important;
				}
				
				
				a {
					display:block;
					height:100%;
				}
				
				img {
					width:100%;
				}
			}
			
			#offres {
				
				.offre {

					height:375px;
					position:relative;
					
					@media only screen and (max-width: 990px) {
						height:315px;
						background:url('img/offre_mobile_bckgrd.jpg') center -36px;
					}
					
					@media only screen and (min-width: 756px) and (max-width: 990px) {
						height:290px;
					}

					.background {
						mix-blend-mode: multiply;
						-webkit-mix-blend-mode: multiply;
						position:absolute;
						height:100%;
						width:100%;
						top:0;
						left:0;
					}

					.content  {

						a {

							position:absolute;
							height:100%;
							width:100%;
							top:0;
							left:0;
							color:white;
							padding:20px 20px;
							text-transform:uppercase;
							cursor:pointer;

							.nom {
								font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
								font-weight:bold;
								font-size:55px;
								padding:20px 0;
								position:relative;
								padding-left:50px;
								margin-bottom:20px;
								height:86px;
								
								@media only screen and (max-width: 990px) {
									height:70px;
									width:fit-content;
								}
								
							}
							
							.nom:before {
								content:'';
								background:url('img/chevron_blanc.png') 0 0 no-repeat;
								background-size:contain;
								height:50px;
								width:40px;
								display:block;
								position:absolute;
								top:10px;
								left:0;
							}
							.nom:after {
								content:'';
								height:6px;
								width:280px;
								display:block;
								position:absolute;
								bottom:0;
								left:50px;
								background-color:white;
								
								@media only screen and (max-width: 1200px) {
									width:150px;
								}
								@media only screen and (max-width: 990px) {
									width:100%;
								}
							}

							.infos {

								h3 {
									font-size:20px;
									text-transform:normal;
								}
								
								/*padding-left:50px;
								background:url('img/fleche_blanc.png') right 5px top 15px no-repeat;
								background-size:20px;*/
								
								@media only screen and (max-width: 1400px) {
									background-size:80px;
								}
								
								@media only screen and (max-width: 1200px) {
									background-size:60px;
								}
								@media only screen and (max-width: 990px) {
									padding-left:50px;
									background-position:right 30px top 15px;
								}
								@media only screen and (min-width: 756px) and (max-width: 990px) {
									background-size: 100px;
									background-position: right 34px top 2px;
								}
								
								.a_partir {
									font-size:24px;
									display:block;
								}

								.prix {

									.montant {
										font-size:110px;
										font-weight:900;
										line-height: 120px;
										float:left;
										width:43%;
										
										@media only screen and (max-width: 1400px) {
											font-size:100px;
											line-height: 100px;
											width:48%;
										}
										@media only screen and (max-width: 1200px) {
											font-size:85px;
											width:48%;
										}
										@media only screen and (max-width: 990px) {
											width:42%;
										}
										@media only screen and (min-width: 756px) and (max-width: 990px) {
											font-size:125px;
										}

									}
									.mois {
										font-size:24px;
										float:left;
										width:40%;
										padding-top: 13px;
										line-height: 51px;
											
										@media only screen and (max-width: 1400px) {
											font-size:22px;
											line-height: 40px;
										}	
										@media only screen and (max-width: 1200px) {
											font-size:20px;
										}
										@media only screen and (max-width: 990px) {
											width:20%;
										}
										
										strong {
											font-size:60px;
											font-weight:300;
											display:block;
											
											@media only screen and (max-width: 1400px) {
												font-size:50px;
											}
											@media only screen and (max-width: 1200px) {
												font-size:40px;
											}
										}
									}
								}

								.engagement {
									clear:both;
									display:block;
									
									@media only screen and (max-width: 990px) {
										font-size:13px;
									}
									
								}

								.adhesion {
									position:absolute;
									bottom:0;
									width:90px;
									right:50px;
									font-size:18px;
									font-weight:500;
									border-left:3px solid white;
									padding-left:12px;
									padding-bottom:20px;
									
									@media only screen and (max-width: 1400px) {
										width:90px;
										right:40px;
										font-size:16px;
										padding-bottom: 10px;
									}
									@media only screen and (max-width: 1200px) {
										width:90px;
										right:40px;
										font-size:16px;
										padding-bottom: 10px;
									}
									
									@media only screen and (min-width: 756px) and (max-width: 990px) {
										width:220px;
										right:10px;
										padding-top: 9px;
									}

									strong {
										font-size:26px;
										font-weight:900;
										display:block;
										
										@media only screen and (max-width: 1400px) {
											font-size:20px;
										}
										
										@media only screen and (max-width: 1200px) {
											font-size:20px;
										}
										
										@media only screen and (min-width: 756px) and (max-width: 990px) {
											display:inline-block;
										}
									}
								}

							}
						}

					}
				}
				
			}
			
			
		}
		
		#salles {
			
			position:relative;
			min-height:380px;
			
			@media only screen and (max-width: 990px) {
				min-height:420px;
				background-color:white;
				padding-top:40px;
			}
			
			.container,
			.container .row {
				min-height:300px;
			}
			
			.top {
				
				z-index:2;
				/*position:relative;
				height:calc(50% + 14px);*/
				
				.titre {
					padding-left:20px;
					padding-right:20px;
					font-size:40px;
					font-weight:900;
					text-transform:uppercase;
					background-color:white;
					display:inline-block;
					color:$vert-fonce;
					position:absolute;
					bottom:calc(50% - 14px);
					z-index:2;
					
					@media only screen and (max-width: 990px) {
						bottom:unset;
						position:relative;
						text-align:center;
						width:100%;
					}
					
				}
				.lieux {
					
					position:absolute;
					bottom:calc(50% + 24px);
					left:49%;
					z-index:2;
					
					@media only screen and (max-width: 1200px) {
						left:53%;
					}
					@media only screen and (max-width: 990px) {
						top:35%;
						left:37%;
					}
					
					.salle {

						float:left;
						margin-left:-90px;
												
						a {
							position:relative;
							transform: rotate(-45deg);
							padding-left:50px;
							width:fit-content;
							display:block;
							font-family:'Lovelo Line light','Comfortaa', sans-serif;
							font-size:25px;
							font-weight:300;
							color:$vert-fonce;
							
							strong {
								font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
								font-weight:bold;
							}
							
						}
						a:before {
							content:'';
							height:40px;
							width:40px;
							display:block;
							background:url('img/salle_icone.png') 0 0 no-repeat;
							background-size:contain;
							position::absolute;
							top:0;
							left:0;
						}
					}
				}
			}
			
			h3 {
				margin-top:40%;
				font-family:'Montserrat','Comfortaa', sans-serif;
				font-size:14px;
				
				@media only screen and (max-width: 1400px) {
					margin-top:46%;
				}
				@media only screen and (max-width: 1200px) {
					margin-top:260px;
				}
				@media only screen and (max-width: 990px) {
					margin-top:210px;
				}
			}
			
			
			
			
		}
		#salles:after {
			background:url('img/salle_bckgrd.png') right bottom no-repeat;
			background-size:contain;
			position:absolute;
			content:'';
			height: 100%;
			aspect-ratio: 1/0.69;
			z-index:3;
			bottom:0;
			right:0;
			
			@media only screen and (max-width: 1200px) {
				height: 80%;
			}
			@media only screen and (max-width: 990px) {
				display:none;
			}
		}
		
		
		#salles:before {	
			position:absolute;
			content:'';
			width:100%;
			height:6px;
			top:50%;
			background-color:$vert-fonce;
			z-index:1;
			
			@media only screen and (max-width: 990px) {
				top:45%;
			}
			@media only screen and (max-width: 990px) {
				top:52%;
			}
			
		}
		
		#small {
			background:url('img/small_bckgrd.jpg') 0 0 no-repeat;
			background-size:cover;
			padding-top:50px;
			padding-bottom:70px;
			position:relative;
			
			@media only screen and (max-width: 990px) {
				background:url('img/small_mobile_bckgrd.jpg') right 0 no-repeat;
				background-size:cover;
			}
			
			* {
				color:white;
			}
			
			a {
				
				
				font-size:30px;
				font-weight:300;
				
				h2 {
					font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
					font-weight:bold;
					font-size:65px;
					
					@media only screen and (max-width: 990px) {
						width:65%;
						font-size:43px;
					}
				}

				h3 {
					margin:30px 0;
					font-size:14px;
					width:70%;
					
					@media only screen and (max-width: 990px) {
						width:80%;
					}
				}
				
			}
			
			.appli {
				
				width:500px;
				position:absolute;
				left:47%;
				bottom:15px;
				z-index:999;
				
				.img {
					width:100%;
				}
				
				@media only screen and (max-width: 990px) {
					position:relative;
					bottom:unset;
					left:unset;
					width:80%;
					margin:0 auto;
				}
				@media only screen and (max-width: 768px) {
					width:100%;
				}
				
				a {
					display:block;
					height:100%;
				}
			}
			
			
		}
		#small:after {
			background:url('img/small_bckgrd_right.png') right bottom no-repeat;
			mix-blend-mode: hard-light;
			-webkit-mix-blend-mode: hard-light;
			background-size:contain;
			position:absolute;
			content:'';
			height: 100%;
			aspect-ratio: 1/0.69;
			z-index:3;
			bottom:0;
			right:0;
			z-index:998;
			
			@media only screen and (max-width: 990px) {
				display:none;
			}
		}
		
		#indoorphine {
			border-top: solid 20px $vert-2;
			background:url('img/page_bckgrd.jpg') 0 0 no-repeat;
			background-size:cover;
			padding-top:30px;
			padding-bottom:55px;
			position:relative;
			
			
			@media only screen and (max-width: 990px) {
				padding-bottom:120px;
			}
			
			.titre {
				margin-left:5%;
				margin-top:10px;

				@media only screen and (max-width: 990px) {
					margin-left:0;
				}
			}
			
			h2 {
				font-weight:900;
				color:$vert-fonce;
				clear:both;
				font-size:24px;
			}
			
			h3 {
				font-weight:500;
				font-size:14px;
			}
			.lien {
				font-size:25px;
				text-align:right;
				text-transform:uppercase;
				margin-top:20px;
			}
			
			.img {
				content:'';
				width:38%;
				position:absolute;
				bottom:0;
				left:40px;
				@media only screen and (max-width: 850px) {
					width:60%;
				}
			}
			
		}
		
		#temoignages {
			background:url('img/temoignages_bckgrd.jpg') 0 0;
			background-size:cover;
			padding-top:70px;
			position:relative;
			
			* {
				color:white;
			}
			
			h2 {
				font-style:italic;
				font-size:30px;
				line-height:32px;
				position:relative;
				text-align:right;
				margin-left:30%;
				margin-right:30%;
				margin-bottom:50px;
				
				@media only screen and (max-width: 1200px) {
					margin-left:25px;
					margin-right:115px;
				}
				@media only screen and (max-width: 990px) {
					margin-left:25px;
					margin-right:115px;
					font-size:25px;
				}
				
				img {
					
					position:absolute;
					width:90px;
					top:10px;
					right:-110px;
					
					@media only screen and (max-width: 990px) {
						width:50px;
						right:-60px;
					}
				}
				
			}
			
			
			.temoignage {
				
				padding-left:8px;
				padding-right:8px;
				
				.content {
					aspect-ratio:1/1.1;
					background-size:cover !important;
					position:relative;
					
					.img {
						mix-blend-mode: luminosity;
						-webkit-mix-blend-mode: luminosity;
						
						@media only screen and (max-width: 768px) {
							img {
								width:100%;
							}
						}
						
					}
					
					.text {
					
						position:absolute;
						bottom:0;
						left:0;
						width:100%;
						padding:20px;
						background-color:rgba(0,0,0,0.7);
						strong {
							font-weight:900;
							text-transform:uppercase;
						}
						
					}
					
				}
				
			}
			.temoignage:hover {
				.img {
					mix-blend-mode: normal;
					-webkit-mix-blend-mode: normal;
				}
			}
		}
		
	}
}

/* PAGE STANDARD */
body.page-template-default {
	
	.site-container {
		
		#content {
			background:url('img/page_header_bckgrd.jpg') center 0 no-repeat;
			padding-top:36px;
			
			.page-content {
				
				background:url('img/page_bckgrd.jpg') center 0 white;
				
				> .container {
					
					background-color:white;
					
					> .row {

						box-shadow: -20px 0 20px -20px #333, 20px 0 20px -20px #333;
						padding-top:40px;
						padding-bottom:80px;
						
						h1 {
							font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
							font-size:30px;
							color:$orange;
							text-align:center;
						}
						
						h2 {
							font-size:20px;
							color:$orange;
							font-weight:700;
						}
						
						.content {
							padding-top:30px;
						}

					}
				}
			}
		}
	}
}

/* PAGE INTERIEURE BLOCK */

body.page-template-page_interieure {
	
	.site-container {
		
		#content {
			background:url('img/page_header_bckgrd.jpg') center 0 no-repeat;
			padding-top:36px;
			
			.page-block {
				
				background:url('img/page_bckgrd.jpg') center 0 white;
				
				> .container > .row {
				
					box-shadow: -20px 0 20px -20px #333, 20px 0 20px -20px #333;
					
				}
				
				.block-page {
					padding:30px 40px;
					background:white;
					
					.content {
						
						a, a:visited {
							text-decoration:underline !important;
						}
						
						h1 {
							text-align:right;
							font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
							font-weight:700;
							font-size:30px;
							color:$orange;
							position:relative;
							padding-bottom:20px;
							
							strong {
								font-size:65px;
							}
						}
						h1:after {
							content:'';
							height:6px;
							width:235px;
							background-color:$orange;
							position:absolute;
							bottom:0;
							right:0;
						}
						
						h2 {
							background:url('img/chevron_orange.png') 0 center no-repeat white;
							font-size:16px;
							font-weight:700;
							padding: 20px 20px 20px 90px;
							margin:20px 0;
							text-transform:uppercase;
							position:relative;
							right:-110px;
							width:110%;
						}
						
						h3 {
							font-size:14px;
							text-align:right;
							
							em {
								font-style:normal;
								color:$orange;
								font-weight:700;
							}
						}
						
					}
					
					.visuel {

						img {
							width:100%;
						}


					}
					
				}
				
				.cta {
							
					margin-top:30px;

					a {

						display:block;
						background:url('img/chevron_vert.png') 0 0 no-repeat;
						background-size:50px;
						padding : 0 0 0 60px;
						color:$vert-fonce;
						font-weight:bold;
						font-size:26px;

						.type {
							font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
							font-weight:700;
							color:$vert-fonce;
							margin-top:10px;
							display:block;
						}
						strong {
							font-weight:700;
							background-color:$vert-2;
							color:white;
							display:inline-block;
							padding:6px 15px;
							margin-top:10px;
							-webkit-border-top-right-radius: 10px;
							-webkit-border-bottom-right-radius: 10px;
							-moz-border-radius-topright: 10px;
							-moz-border-radius-bottomright: 10px;
							border-top-right-radius: 10px;
							border-bottom-right-radius: 10px;
							position:relative;
						}
						strong:after {
							content:'';
							height: 40px;
							width: 40px;
							position: absolute;
							top: -2px;
							right: -50px;
							background:url('img/fleche_vert.png') 0 0 no-repeat;
							background-size:cover;
						}

					}

				}
				
				
				.cta-decouverte {
					
					margin-left:20px;
					margin-top:40px;
					padding:8px 40px 8px 15px;
					width:calc(100% - 60px);
					border:solid 3px $orange;
					border-radius:30px;
					font-size:22px;
					font-weight:bold;
					background:url('img/fleche_orange.png') no-repeat;
					background-position: right 5px top 1px;
					background-size:36px;
					
					@media only screen and (max-width: 990px) {
						background-position: right 5px top 50%;
						width:calc(100% - 40px);
					}
					
					a {
						color:$orange;
						strong {
							font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
							font-weight:700;
						}
					}
					
				}
				
				.cta-contact {
					
					color:$orange;
					font-weight:bold;
					font-size:22px;
					margin-top:30px;
					margin-left:10px;
					
					.btn_contact, .btn_tel, .btn_email, .btn_web {
						text-decoration:none;
						display:inline-block;
						height:100%;
						color:$orange;
						font-size:22px;
						font-weight:bold;
					}
					.btn_contact {
						border:solid 2px $orange;
						border-radius:20px;
						padding:6px 12px;
						margin-left:15px;
					}
					.btn_tel, .btn_email, .btn_web {
						text-indent:-9000px;
						background: url(img/tel.svg) center center no-repeat;
						width:30px;
						height:30px;
						background-size:contain;
						top: -5px;
						position: relative;
						margin-left:10px;
					}
					.btn_email {
						background-image: url(img/email.svg);
						width:37px;
					}
					.btn_web {
						background-image: url(img/web.svg);
					}
					
				}
				
				.block-page.texte-visuel {
					.content {
						padding-right:60px !important;
					}
				}
				.block-page.visuel-texte {
					
					.content {
						
						padding-left:60px !important;
						
						h1 {
							text-align:left;
							
						}
						h1:after {
							left:0;
						}
						
						h2 {
							background-position:20px center;
							padding: 20px 20px 20px 110px;
							left:-160px;
						}
						
						h3 {
							text-align:left;
						}
						
					}
					
					.visuel {

					}
					
				}
				
				
				
				
			}
			
		}

	}
	
}


/*PAGE OFFRES */


body.page-template-page_offres {
	
	#content  {
		background: url(img/page_bckgrd.jpg) center 0;
		
	}
	
	.page-offres {
		
		background: url(img/offre_bckgrd.jpg) center 0 no-repeat;
		padding-top: 97px;
		
		@media only screen and (max-width: 990px) {
			padding-top: 50px;
		}
		.tab-offres {
			
			padding-top:6px;
			padding-bottom:20px;
			
			.row.first {
				
				margin-bottom:6px;
				
				.offre {
				
					position:relative;
					color:white;
					
					.background {
						mix-blend-mode: multiply;
						-webkit-mix-blend-mode: multiply;
						position:absolute;
						height:100%;
						width:calc(100% - 6px);
						top:0;
						left:3x;
						z-index:0;
					}

					.content  {
						
						position:relative;
						z-index:1;
						
						.nom {
							font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
							font-weight:bold;
							font-size:50px;
							padding:32px 0;
							position:relative;
							margin-bottom:10px;
							border-bottom:solid 6px white;
							width:fit-content;
							margin:0 auto;
							
							@media only screen and (max-width: 1400px) {
								font-size:40px;
							}
							@media only screen and (max-width: 1200px) {
								font-size:38px;
							}
						}
						.nom:before {
							content:'';
							background:url('img/chevron_blanc.png') 0 0 no-repeat;
							background-size:contain;
							height:50px;
							width:40px;
							display:block;
							position:absolute;
							top:22px;
							left:-50px;
						}
						.nom.asterisque:after {
							content:'*';
							display:block;
							position:absolute;
							top:37px;
							right:-25px;
						}
						
						
						.infos {

							padding-left:50px;
							background:url('img/fleche_blanc.png') right 5px top 15px no-repeat;
							
							@media only screen and (max-width: 1200px) {
								padding-left: 30px;
							}
							
							.prix {
								
								.montant {
									font-size:110px;
									font-weight:900;
									line-height: 120px;
									float:left;
									width:42%;
									
									@media only screen and (max-width: 1400px) {
										font-size:100px;
									}
									@media only screen and (max-width: 1400px) {
										font-size:90px;
										width:inherit;
									}

								}
								.mois {
									font-size:24px;
									float:left;
									text-align:right;
									width:40%;
									padding-top: 13px;
									line-height: 51px;
									
									@media only screen and (max-width: 1200px) {
										font-size:20px;
										width:inherit;
									}
									
									strong {
										font-size:60px;
										font-weight:300;
										display:block;
										
										@media only screen and (max-width: 1200px) {
											font-size:40px;
										}
									}
								}
							}

						}


					}
				}
			
			}
			
			.row {
				
				.offre {
					text-align:center;
					padding-right:3px;
					padding-left:3px;
					font-size:20px;

					.true {
						text-indent:-9000px;
						background: url(img/true_vert_0.png) center 0 no-repeat;
						background-size:contain;
						height:50px;
						width:50px;
						margin: 15px auto;
					}
					.true.true_1 {
						background-image: url(img/true_vert_1.png);
					}
					.true.true_2 {
						background-image: url(img/true_vert_2.png);
					}
					.false {
						height:50px;
						width:50px;
						margin: 30px auto;
					}


				}
				
				.offre.data .v-align {
					width:100%;
				}
				
				.offre.data:nth-child(1) > div {
					color:$main;
					text-align:left !important;
					border-top:solid 4px $main;
					padding-top:15px;
					padding-bottom:10px;
				}

				.offre.data:nth-child(2) > div {
					color:#2e9e71;
					border-top:solid 4px #2e9e71;
					text-transform:uppercase;
				}

				.offre.data:nth-child(3) > div {
					color:#077f6c;
					border-top:solid 4px #077f6c;
				}

				.offre.data:nth-child(4) > div {
					color:#034e42;
					border-top:solid 4px #034e42;
				}

				h2 {
					font-size:22px;
					font-weight:900;
					text-transform:uppercase;
					margin:0;

					strong {
						font-size:30px;
					}
				}

				h3 {
					font-size:14px;
				}

			}

			.row:last-child {
				.offre.data:nth-child(1) > div {
					border-bottom:solid 4px $main;
				}
				.offre.data:nth-child(2) > div {
					border-bottom:solid 4px #2e9e71;
				}
				.offre.data:nth-child(3) > div {
					border-bottom:solid 4px #077f6c;
				}
				.offre.data:nth-child(4) > div {
					border-bottom:solid 4px #034e42;
				}
			}

			.frais_adhesion {
				.offre {
					font-size:50px;
				}
			}
			
		}
		
		.tab-inscription {
		
			.row {
				
				margin-bottom:6px;
				
				.sportif {
					background:url('img/sportif.png') 0 bottom no-repeat;
					background-size:contain;
				}
				
				.offre {
				
					position:relative;
					color:white;
					
					
					.background {
						mix-blend-mode: multiply;
						-webkit-mix-blend-mode: multiply;
						position:absolute;
						height:100%;
						width:calc(100% - var(--bs-gutter-x) * .5);
						top:0;
						left:3x;
						z-index:0;
					}

					.content  {
						
						position:relative;
						z-index:1;
						padding-left:90px;
						padding-top:5px;
						padding-bottom:20px;
						
						.nom {
							
							font-size:20px;
							font-weight:bold;
							line-height:40px;
							
							strong {
								font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
								font-weight:bold;
								font-size:45px;
								display:block;
							}
							
						}
						
						@media only screen and (max-width: 1400px) {
							.nom {
								strong {
									font-size:37px;
								}
							}
						}
						@media only screen and (max-width: 1200px) {
							.nom {
								font-size:20px;
								strong {
									font-size:32px;
								}
							}
						}
						
						.inscription {
							display:block;
							position:absolute;
							bottom:-30px;
							right:-20px;
							background-color:$orange;
							padding:4px 8px;
							a {
								color:white;
								font-weight:bold;
								display:block;
								height:100%;
								padding:3px 34px 3px 4px;
								background:url('img/fleche_blanc.png') right 0 no-repeat;
								background-size:contain;
								cursor:pointer;
							}
						}

					}
					
					.content:before {
						content:'';
						background:url('img/chevron_blanc.png') 0 0 no-repeat;
						background-size:contain;
						height:55px;
						width:55px;
						display:block;
						position:absolute;
						top:20px;
						left:20px;
					}
					
					
				}
				
				.options {
					
					margin-top:20px;
					
					.row {
						margin-top:10px;
					}
					
					.row > * {
						padding-left:4px;
						padding-right:4px;
					}
					
					margin-top:80px;
					
					.titre {
						font-size:30px;
						font-weight:bold;
						text-transform:uppercase;
						margin-bottom:20px;
						
						strong {
							font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
							font-weight:bold;
							font-size:45px;
							color:$vert-menu;
						}
					}
					.texte {
						
						font-size:16px;
						line-height:18px;
						
						strong {
							display:block;
							
						}
					}
					.prix {
						
						span {
							display:block;
							background-color:$vert-menu;
							color:white;
							text-align:center;
							padding:6px 0;
							font-size:16px;
							
							strong {
								font-size:24px;
							}
						}
					}
					

				}
			
			}
			
		}
		
		
		
		
		
	}
	
	
	.page-offres-mobile {
		
		background:white;
	
		.offre {
			
			background:url('img/page_bckgrd.jpg') center 0;
			border-bottom:solid 20px white;
			
			.titre {

				position:relative;
				background: url(img/temoignages_bckgrd.jpg) center 0 no-repeat;
				color:white;
				padding:10px 0;

				.background {
					mix-blend-mode: multiply;
					-webkit-mix-blend-mode: multiply;
					position:absolute;
					height:100%;
					width:100%;
					top:0;
					left:0;
				}

				.nom {
					font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
					font-weight:bold;
					font-size:45px;
					position:relative;
					padding-left:50px;
					padding-right:0;
					margin-top:20px;
				}

				.nom:before {
					content:'';
					background:url('img/chevron_blanc.png') 0 0 no-repeat;
					background-size:contain;
					height:50px;
					width:40px;
					display:block;
					position:absolute;
					top:-12px;
					left:0;
				}

				.tarif {
					position:relative;
					margin-top:14px;
					padding-left:0;
					padding-right:0;

					strong {
						font-size:45px;
						font-weight:900;
					}
					em {
						position:absolute;
						top:-10px;
						font-size:25px;
						font-style:normal;
						font-weight:300;
					}
				}
			}

			.intro {

				ul {

					list-style:none;
					padding:15px 0;
					margin:0;

					li {
						background: url(img/true_vert_0.png) 0 6px no-repeat;
						background-size:20px;
						padding:6px 0;
						padding-left:25px;
						
						
						em {
							font-weight:700;
							font-style:normal;
							color:$vert-menu;
						}
						strong {
							font-weight:900;
							text-transform:uppercase;
							color:$vert-menu;
						}

					}
					li.strong {
						background-position:6px 6px;
						border-right:2px solid $vert-fonce;
						border-left:2px solid $vert-fonce;
						padding: 6px 6px 6px 25px;
					}
					li.strong.first {
						border-top:2px solid $vert-fonce;
					}
					li.strong.last {
						border-bottom:2px solid $vert-fonce;
					}

				}
				ul.intro-1 {

					li {
						background-image: url(img/true_vert_1.png);
						em {
							color:$vert-2;
						}
						strong {
							color:$vert-2;
						}
					}
				}
				ul.intro-2 {
					li {
						background-image: url(img/true_vert_2.png);
						em {
							color:$vert-fonce;
						}
						strong {
							color:$vert-fonce;
						}
					}
				}

			}


			.options {

				ul {

					list-style:none;
					padding:15px 0;

					li {
						padding-left:25px;
						margin:6px 0;

						em {
							font-weight:700;
							font-style:normal;
							color:$vert-menu;
						}
						strong {
							font-weight:900;
							text-transform:uppercase;
						}

					}
					li:first-child {
						font-weight:bold;
					}

				}
				ul.option-1 {

					li {
						em {
							color:$vert-2;
						}
					}
				}
				ul.option-2 {
					li {
						em {
							color:$vert-fonce;
						}
					}
				}

			}

			
			.bas {

				position:relative;
				color:white;
				
				> div {
					position:relative;
				}
				
				.background {
					mix-blend-mode: multiply;
					-webkit-mix-blend-mode: multiply;
					position:absolute;
					height:100%;
					width:100%;
					top:0;
					left:0;
				}

				.nom {
					
					padding:0 5px;
					
					a {
						color:white;
						padding:8px 0;
						padding-left:46px;
						display:block;

						strong {
							font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
							font-weight:bold;
							font-size:25px;
						}
					}
				}

				.nom:before {
					content:'';
					background:url('img/chevron_blanc.png') 0 0 no-repeat;
					background-size:contain;
					height:22px;
					width:22px;
					display:block;
					position:absolute;
					left: 20px;
					top: 10px;
				}

				.inscription {
					background:url('img/fleche_blanc.png') right 10px top 5px no-repeat;
					background-size:30px;
					background-color:$orange;
					a {
						color:white;
						font-size:14px;
						font-weight:700;
						padding:8px 0;
						display:block;
						cursor:pointer;
					}
				}
			}
			
		}
		
		
	}
	
	
	.tab-options {
		background-color:white;
		padding-top:20px;
		
		@media only screen and (max-width: 990px) {
			padding-top:0;
		}
		
		.option {
			background:url('img/down_vert.png') 20px 0 no-repeat;
			background-size:50px;
			padding-top:60px;
			position:relative;
			
			@media only screen and (max-width: 990px) {
				background:transparent;
				padding-top:0px;
			}
			
			.content {

				border-right:solid 8px $vert;
				padding-bottom:50px;
				@media only screen and (max-width: 990px) {
					padding-left:90px;
					border:none;
					border-bottom:solid 8px $vert;
					padding-bottom:30px;
					margin-bottom:30px;
				}

				.titre {
					color:$vert;
					font-size:35px;
					font-weight:bold;
					line-height:36px;
					padding-bottom:10px;
					margin-bottom:10px;
					border-bottom:solid 4px $vert-fonce;
					width: fit-content;

					strong {
						font-size:40px;
						display:block;
						font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
						font-weight:bold;
						
						@media only screen and (max-width: 990px) {
							display:inline;
						}
					}
				}

				.texte {
					width:90%;
				}

				.prix {
					margin-top:20px;
					color:$vert-fonce;
					font-size:40px;
					font-weight:bold;
				}

			}
		}

		.option.s-track:after,
		.option.programme:after,
		.option.test:after {
			content:'';
			position:absolute;
			width: 120px;
			height:80px;
			right: 60px;
			top: 48px;
			display:block;
			background:url('img/3_mois.png') 0 0 no-repeat;
			background-size:contain;
			
			@media only screen and (max-width: 990px) {
				left:15px;
				top:0;
				height:80px;
			}
		}

		.option.programme:after {
			width: 100px;
			height:80px;
			right: 45px;
			top: 48px;
			background:url('img/chrono.png') 0 0 no-repeat;
			background-size:contain;
			
			@media only screen and (max-width: 990px) {
				top:0;
			}
		}

		.option.test:after {
			width: 100px;
			height:80px;
			right: 20px;
			top: 48px;
			background:url('img/test.png') 0 0 no-repeat;
			background-size:contain;
			
			@media only screen and (max-width: 990px) {
				top:0;
			}
		}

		.row > .option:last-child {
			.content {
				border-right:none;
				border-bottom:none;
			}
		}

		@media only screen and (max-width: 1400px) {
			.option {
				.content {

					.titre {
						font-size:28px;
						line-height:36px;
						strong {
							font-size:32px;
						}
					}
					.texte {
						width:88%;
					}
					.prix {
						font-size:40px;
					}

				}
			}
			.option.s-track:after {
				width: 90px;
			}

			.option.programme:after {
				width: 75px;
			}

			.option.test:after {
				width: 80px;
			}
		}
		@media only screen and (max-width: 1200px) {
			.option {
				.content {

					.titre {
						font-size:24px;
						line-height:30px;
						strong {
							font-size:28px;
						}
					}
					.texte {
						width:90%;
						font-size:14px;
					}
					.prix {
						font-size:40px;
					}

				}
			}
			.option.s-track:after {
				width: 80px;
			}

			.option.programme:after {
				width: 65px;
			}

			.option.test:after {
				width: 70px;
			}
		}

	}

}


/* BLOC PERSONAL TRAINING */

#personal {
				
	padding-top:60px;
	padding-bottom:60px;
	position:relative;
	background:url('img/page_bckgrd.jpg') center 0;
	
	.content {

		position:relative;
		
		@media only screen and (max-width: 990px) {
			padding-bottom:140px;
		}
		@media only screen and (max-width: 768px) {
			padding-bottom:100px;
		}
		
		h2 {
			font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
			font-weight:bold;
			font-size:55px;
			color:$orange;
			font-weight:bold;
			border-bottom:solid 4px $orange;
			width: fit-content;
			
			@media only screen and (max-width: 1400px) {
				font-size:50px;
			}
			@media only screen and (max-width: 1200px) {
				font-size:42px;
			}
			@media only screen and (max-width: 990px) {
				font-size:45px;
				margin-left:50px;
			}
		}

		h3 {
			margin-top:30px;
			font-size:14px;
		}
	}

	.content:before {
		height:50px;
		width:40px;
		display:block;
		background:url('img/chevron_orange.png') 0 0 no-repeat;
		background-size:contain;
		content:'';
		position:absolute;
		top:15px;
		left:-50px;
		
		@media only screen and (max-width: 990px) {
			left:0px;
		}
		
	}

	.content:after {
		height:160px;
		width:130px;
		display:block;
		background:url('img/fleche_orange.png') 0 0 no-repeat;
		background-size:contain;
		content:'';
		position:absolute;
		bottom:0;
		right:-200px;
		
		@media only screen and (max-width: 990px) {
			right:0px;
			bottom:-50px;
		}
		@media only screen and (max-width: 576px) {
			display:none;
		}
	}
	
	.img {
		width:29%;
		display:block;
		position:absolute;
		bottom:0px;
		left:0px;

		@media only screen and (max-width: 768px) {
			width:230px;
		}
	}
	

}


body.page-template-page_offres {
	#personal {
		border-top:solid 20px $orange;
	}
	
	@media only screen and (max-width: 990px) {
		.small,
		.indoorphine {
			padding-left:35px;
			padding-right:35px;
		}
	}
}

/* BLOC FOOTER PAGE INTERIEURE */

.page-block-footer {
			
	.indoorphine {
		border-top:solid 20px $vert-2;
		background: url(img/footer_indoorphine_bckgrd.jpg) 0 bottom;
		position:relative;
		padding-bottom:20px;
		
		@media only screen and (max-width: 990px) {
			width:calc(100% - 20px);
		}
		
		.titre {
			margin-left:18%;
			margin-top:10px;
			width:50%;
			
			@media only screen and (max-width: 990px) {
				margin-left:0;
				padding-left:15px;
				padding-right:15px;
			}
		}

		.content {

			margin-left:45%;
			
			@media only screen and (max-width: 990px) {
				margin-left:0;
				padding-bottom:40px;
				padding-left:15px;
				padding-right:15px;
			}

			h2 {
				font-weight:900;
				color:$vert-fonce;
				clear:both;
				font-size:22px;
			}

			h3 {
				font-weight:500;
				font-size:14px;
			}
			.lien {
				font-size:25px;
				text-align:right;
				text-transform:uppercase;
				margin-top: 40px;
			}
		}
			
		
		.img {
			content:'';
			width:40%;
			position:absolute;
			bottom:0;
			left:40px;
		}
	}
	

	.small {

		background: url(img/footer_small_bckgrd.jpg) 0 bottom;
		background-size:cover;

		padding-top:30px;
		padding-bottom:20px;
		position:relative;
		
		@media only screen and (max-width: 990px) {
			padding-bottom:20px;	
			width:calc(100% - 20px);
		}
		
		* {
			color:white;
		}

		a {

			font-size:30px;
			font-weight:300;
			
			h2 {
				font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
				font-weight:700;
				font-size:50px;
				margin-bottom:0;
			
				@media only screen and (max-width: 990px) {
					padding-left:15px;
					padding-right:15px;
				}

			}

			h3 {
				margin:20px 0;
				font-size:14px;
				width:80%;
				@media only screen and (max-width: 990px) {
					width:100%;
					padding-left:15px;
					padding-right:15px;
				}
			}
			.lien {
				font-size:25px;
				text-transform:uppercase;
				margin-top: 40px;
				@media only screen and (max-width: 990px) {
					padding-left:15px;
					padding-right:15px;
				}
			}

		}

		.appli {
				
				width:500px;
				position:absolute;
				left:50px;
				bottom:20px;
				
				.img {
					width:100%;
				}
				
				@media only screen and (max-width: 990px) {
					position:relative;
					bottom:unset;
					left:unset;
					width:80%;
					margin:0 auto;
					padding-left:15px;
					padding-right:15px;
				}
				@media only screen and (max-width: 768px) {
					width:100%;
				}
				
				a {
					display:block;
					height:100%;
				}
			}

	}

}