
@media only screen and (max-width: 990px) {
	
	
	body.page-template-page_interieure {
	
		.site-container {

			#content {
				background:url('img/page_header_bckgrd.jpg') center 0 no-repeat;
				padding-top:36px;

				.page-block {
					
					.block-page {
						padding:10px;

						.content {

							h1 {
								text-align:center !important;
								font-size:25px;
								padding-bottom:10px;
								padding-top:10px;
								
								strong {
									font-size:45px;
								}
							}
							h1:after {
								height:3px;
								width:100%;
							}

							h2 {
								font-size:15px;
								right:0;
								padding-left:50px;
								padding-right:0;
								width:100%;
								background-size:40px;
							}

							h3 {
								font-size:15px;
								text-align:justify;

							}

						}

						.visuel {

							img {
								width:100%;
							}


						}

					}

					.cta {

						margin-top:30px;

						a {

							background-size:30px;
							padding : 0 0 0 40px;
							font-size:22px;

							.type {
								font-size:20px;
							}
							strong {
								font-size:18px;
							}
							strong:after {
								height: 36px;
								width: 36px;
								top: -1px;
								right: -50px;
							}

						}

					}

					.block-page.texte-visuel {
						.content {
							padding-right: calc(var(--bs-gutter-x) * .5) !important;
						}
					}
					.block-page.visuel-texte {

						.content {

							padding-left: calc(var(--bs-gutter-x) * .5) !important;

							h1 {
								text-align:justify;

							}
							h1:after {
								left:0;
							}

							h2 {
								background-position:0px center;
								padding-left:50px;
								padding-right:0;
								left:0;
							}

							h3 {
								text-align:left;
							}

						}

						.visuel {

						}

					}




				}

			}

		}

	}

}

@media only screen and (max-width: 768px) {
	
	
	

}

@media only screen and (max-width: 576px) {
	
	
	

}