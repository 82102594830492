@font-face {
	 font-family: "Lovelo Black";
	 src: url("fonts/Lovelo Black.otf") format("opentype");
	}
	@font-face {
	 font-family: "Lovelo Line Bold";
	 src: url("fonts/Lovelo Line Bold.otf") format("opentype");
	}
	@font-face {
	 font-family: "Lovelo Line Light";
	 src: url("fonts/Lovelo Line Light.otf") format("opentype");
}

@supports ( -webkit-touch-callout : none) {
.selector {
background-attachment:scroll
}
}

@supports not ( -webkit-touch-callout : none) {
.selector {
background-attachment: fixed;
}
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	background-size:cover;
	background-repeat:no-repeat;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}


body{
    color:$main;
	font-family: 'Montserrat', sans-serif;
	font-size: $default-font-size;
    line-height: $default-line-height;
    font-weight: $default-font-weight;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow-x: hidden;
	font-size:14px;
}

.container-fluid {
	padding-left:0;
	padding-right:0;
}

.site-container {
	
	padding-top:40px;
	padding-bottom:80px;
	
	@media only screen and (max-width: 1200px) {
		padding-bottom:100px;
	}
	
	@media only screen and (max-width: 990px) {
		padding-bottom:240px;
		padding-top:100px;
	}
	
	#connect {
		
		background-color:$gris;
		height:34px;
		top:0;
		position:fixed;
		z-index:999999;
		
		@media only screen and (max-width: 990px) {
			> .container {
				margin:0;
				padding:0;
			}
		}
		
		ul {
			
			text-align:right;
			margin:0;
			
			
			
			li {
				
				display:inline-block;
				@media only screen and (max-width: 990px) {
					/*display:block;*/
				}
				padding:6px 20px;
				
				a {
					text-decoration:none;
					font-size:15px;
				}
			}
			
			li.inscription {
				background-color:$orange;
				a {
					font-weight:900;
					font-size:15px;
					text-transform:uppercase;
					color:white;
					cursor:pointer;
				}
				@media only screen and (max-width: 990px) {
					display:none;
				}
			}
			li.contact {
				a {
					font-weight:900;
					color:$vert;
					cursor:pointer;
				}
			}
			li.connexion {
				a {
					color:white;
				}
			}
		}
	}
	
	header {
		
		position:fixed;
		z-index:9999999;
		top:34px;
		
		@media only screen and (max-width: 990px) {
			
			height: 66px;
			
			> .container {
				padding:0;
			}
			
			background-color:$gris;
		}
		
		background-color:white;
		
		.navbar {
			padding:0;
			margin:0;
			
			.navbar-brand {
				
				position:absolute;
				top:-40px;
				width:110px;
				
				@media only screen and (max-width: 990px) {
					width:90px;
					margin-right:0;
				}
				
				img {
					padding:16px;
					background-color:white;
					max-width:100%;
				}
				
				.signature {
					
					position:absolute;
					top:16px;
					left:118px;
					
					@media only screen and (max-width: 990px) {
						top:unset;
						bottom: 5px;
						left: 95px;
					}
					
					.sport {
						font-weight:500;
						color:white;
						font-style:normal;
						font-size:12px;
						
						@media only screen and (max-width: 990px) {
							border:none;
							margin:0;
							padding:0;
						}
					}
					
					.ouverture {
						font-size:12px;
						font-weight:300;
						font-style:italic;
						color:$vert;
						margin-left:5px;
						@media only screen and (max-width: 990px) {
							margin:0;
							margin-top:-5px;
							font-weight:300;
							font-style:italic;
							color:$vert;
							display:block;
							
						}
					}
					
				}
			}
			
			
			.navbar-toggler {
				
				position: absolute;
				right: 5px;
				top:20px;
				border:none;
				
				.navbar-toggler-icon {
					background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 78, 0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
				}
				
			}
			
			#navbarNavDropdown {
				
				padding-left:150px;
				
				@media only screen and (max-width: 990px) {
					position:absolute;
					top:65px;
					left:0;
					padding: 0 20px 20px !important;
					background-color:white;
					width:100%;
					z-index:2;
				}
				
				.navbar-nav {
					
					li {
						
						@media only screen and (max-width: 990px) {
							text-align:center;
						}
						
						a {
							color:$vert-fonce;
							font-size:15px;
							font-weight:500;
							text-transform:uppercase;
							text-decoration:none;
							position:relative;
							padding:10px 0 6px;
							display:block;
							
							@media only screen and (max-width: 990px) {
								font-size:18px;
								padding:14px 0 6px;
							}
						}
						a:after {
							width:16px;
							height:8px;
							content:'';
							background-color:$vert-menu;
							position:absolute;
							bottom:-4px;
							left:0;
							transition-duration: 0.5s;
							
							@media only screen and (max-width: 990px) {
								width:20%;
								left:40%;
								height:4px;
							}
							
						}
						a:hover:after {
							width:50px;
						}
					}

				}
			}
			
		}
		
	}	
	
	.site-content {

		a, a:hover {
			color:$main;
			text-decoration:none;
		}
		img {
			max-width:100%;
		}

	}
	
	
	
	
	footer {
		
		#newsletter {
			
			background:linear-gradient( to bottom, #034E42 0%, #2E9E71 100%);
			padding-top:40px;
			padding-bottom:40px;
			
			@media only screen and (max-width: 990px) {
				text-align:center;
				padding-right:15px;
				padding-left:15px;
			}
			
			.text {
				font-size:18px;
				color:white;
				margin:0;
				padding:0;
				
				p {
					padding-left:10px;
					margin:0;
					margin-bottom:10px;
					@media only screen and (max-width: 990px) {
						padding-left:0;
					}
					strong { 
						font-family:'Lovelo Line Light','Comfortaa', sans-serif;
						font-size:24px;
					}
					
				}
			}
			.text-2 p {
				font-size:14px;
				margin:0 !important;
			}
			.form {
				
				padding-top:8px;
				
				input[type=text] {
					border:none;
					padding:5px 10px;
					margin-top:-2px;
				}
				button {
					font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
					font-size:22px;
					background:transparent;
					border:none;
					color:white;
					margin-left:15px;
					background:url('img/fleche_blanc.png') right 0 no-repeat;
					background-size:contain;
					height:50px;
					padding-right:60px;
				}
			}
			.nf-form-cont {
				
				nf-field {
					float:left;
					width:50%;
					height:auto;
					
					.nf-field-container {
						margin:0;
					}
				}
				
				padding-top:8px;
				
				input[type=email] {
					border:none;
					padding:5px 10px;
					margin-top:-2px;
					height:auto !important;
					font-size:14px;
				}
				.nf-field-element input[type=button] {
					margin-top:-10px;
					font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
					font-size:22px;
					background:transparent;
					border:none;
					color:white;
					margin-left:15px;
					background:url('img/fleche_blanc.png') right 0 no-repeat;
					background-size:contain;
					height:50px;
					padding-right:60px;
				}
				
				.nf-pass.field-wrap .nf-field-element::after {
				  top: -10px;
				  right: 5px;
				}
				.nf-response-msg p {
					font-weight:bold;
					color:white;
				}
				.nf-error.field-wrap .nf-field-element::after {
					height: 28px;
					width: 30px;
					line-height: 30px;
					top:0;
					right:2px;
				}
				.nf-error .nf-error-msg {
				  color: white;;
				}
				.nf-error-msg,
				.nf-after-form-content {
					display:none !important;
				}
			}
		}
		
		#menuFooter {
			
			padding-top:20px;
			background-color:white;
			
			ul {
				margin:0;
				padding:0;
				
				li {
					
					display:inline-block;
					font-size:12px;
					font-weight:300;
					padding:0 5px;
					
					@media only screen and (max-width: 756px) {
						display:block;
					}
					
					a {
						color:$main;
						text-decoration:none;
						font-size:12px;
						font-weight:300;
						padding:0 5px;
					}
				}
			}
			
			
		}
		
		#footer_inscription {
				
			position:fixed;
			z-index:999;
			bottom:0;
			background-color:white;

			.philosophie {
				text-align:right;
				padding:25px 90px 10px 0;
				background:url(img/footer_picto_valeurs.png) right 20px center no-repeat white;
				background-size:60px;
				font-size:14px;
				font-weight:bold;
				color:$orange;
				@media only screen and (max-width: 1200px) {
					padding:30px 90px 10px 0;
				}
				@media only screen and (max-width: 990px) {
					text-align:center;
					padding:20px 90px 10px 0;
				}
				@media only screen and (max-width: 768px) {
					padding:20px 90px 20px 30px;
					background-position:right 30px center;
				}
				
				a {
					font-size:14px;
					font-weight:bold;
					color:$orange;
					text-decoration:none;
				}
			}
			.signature {
				background-color:$orange;
				padding-top:20px;
				padding-bottom:20px;
				text-align:center;
				color:white;
				font-family:'Lovelo Line Bold','Comfortaa', sans-serif;

				strong {
					font-size:30px;
				}
				@media only screen and (max-width: 768px) {
					padding-top:5px;
					padding-bottom:5px;
				}
			}
			.inscription {

				background-color:$orange;
				text-align:center;

				a {
					color:$orange;
					font-family:'Lovelo Line Bold','Comfortaa', sans-serif;
					font-size:22px;
					display:block;
					height:100%;
					width:fit-content;
					padding:25px 60px 20px 10px;
					background:url('img/fleche_orange.png') right 5px center no-repeat;
					background-size:40px;
					background-color:white;
					text-transform:uppercase;
					text-decoration:none;
					cursor:pointer;
					@media only screen and (max-width: 990px) {
						width:100%;
						background-position:right 20px center;
					}
				}
			}

		}
		
	}
	
}

body.page-template-home {

	header {
		
		.navbar {
			.navbar-brand {
				
				@media only screen and (min-width: 990px) {
				
					position:absolute;
					top:-50px;
					width:inherit;

					img {
						padding:30px;
						background-color:white;
					}

					.signature {

						position:unset;;

						.sport {
							font-weight:500;
							color:white;
							font-style:normal;
							display:block;
							border-bottom:solid 1px white;
							margin-bottom:6px;
							padding-bottom:6px;
							margin-top:10px;
							font-size:25px;
						}

						.ouverture {
							font-size:18px;
							font-weight:300;
							font-style:italic;
							color:$vert;
							display:block;
						}

					}
					
				}
			}
			
			#navbarNavDropdown {
				padding-left:250px;
			}
			
		}
		
	}

}
