@import url(../node_modules/bootstrap/dist/css/bootstrap.min.css);
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0d6efd;
  text-decoration: underline; }
  a:hover {
    color: #0a58ca; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 20px);
  padding-left: var(--bs-gutter-x, 20px);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1340px; } }

.row {
  --bs-gutter-x: 40px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 4.16667%; }

.col-2 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-3 {
  flex: 0 0 auto;
  width: 12.5%; }

.col-4 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-5 {
  flex: 0 0 auto;
  width: 20.83333%; }

.col-6 {
  flex: 0 0 auto;
  width: 25%; }

.col-7 {
  flex: 0 0 auto;
  width: 29.16667%; }

.col-8 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-9 {
  flex: 0 0 auto;
  width: 37.5%; }

.col-10 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-11 {
  flex: 0 0 auto;
  width: 45.83333%; }

.col-12 {
  flex: 0 0 auto;
  width: 50%; }

.col-13 {
  flex: 0 0 auto;
  width: 54.16667%; }

.col-14 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-15 {
  flex: 0 0 auto;
  width: 62.5%; }

.col-16 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-17 {
  flex: 0 0 auto;
  width: 70.83333%; }

.col-18 {
  flex: 0 0 auto;
  width: 75%; }

.col-19 {
  flex: 0 0 auto;
  width: 79.16667%; }

.col-20 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-21 {
  flex: 0 0 auto;
  width: 87.5%; }

.col-22 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-23 {
  flex: 0 0 auto;
  width: 95.83333%; }

.col-24 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 4.16667%; }

.offset-2 {
  margin-left: 8.33333%; }

.offset-3 {
  margin-left: 12.5%; }

.offset-4 {
  margin-left: 16.66667%; }

.offset-5 {
  margin-left: 20.83333%; }

.offset-6 {
  margin-left: 25%; }

.offset-7 {
  margin-left: 29.16667%; }

.offset-8 {
  margin-left: 33.33333%; }

.offset-9 {
  margin-left: 37.5%; }

.offset-10 {
  margin-left: 41.66667%; }

.offset-11 {
  margin-left: 45.83333%; }

.offset-12 {
  margin-left: 50%; }

.offset-13 {
  margin-left: 54.16667%; }

.offset-14 {
  margin-left: 58.33333%; }

.offset-15 {
  margin-left: 62.5%; }

.offset-16 {
  margin-left: 66.66667%; }

.offset-17 {
  margin-left: 70.83333%; }

.offset-18 {
  margin-left: 75%; }

.offset-19 {
  margin-left: 79.16667%; }

.offset-20 {
  margin-left: 83.33333%; }

.offset-21 {
  margin-left: 87.5%; }

.offset-22 {
  margin-left: 91.66667%; }

.offset-23 {
  margin-left: 95.83333%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 4.16667%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 20.83333%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 29.16667%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 45.83333%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-13 {
    flex: 0 0 auto;
    width: 54.16667%; }
  .col-sm-14 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-15 {
    flex: 0 0 auto;
    width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-17 {
    flex: 0 0 auto;
    width: 70.83333%; }
  .col-sm-18 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-19 {
    flex: 0 0 auto;
    width: 79.16667%; }
  .col-sm-20 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-21 {
    flex: 0 0 auto;
    width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-23 {
    flex: 0 0 auto;
    width: 95.83333%; }
  .col-sm-24 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 4.16667%; }
  .offset-sm-2 {
    margin-left: 8.33333%; }
  .offset-sm-3 {
    margin-left: 12.5%; }
  .offset-sm-4 {
    margin-left: 16.66667%; }
  .offset-sm-5 {
    margin-left: 20.83333%; }
  .offset-sm-6 {
    margin-left: 25%; }
  .offset-sm-7 {
    margin-left: 29.16667%; }
  .offset-sm-8 {
    margin-left: 33.33333%; }
  .offset-sm-9 {
    margin-left: 37.5%; }
  .offset-sm-10 {
    margin-left: 41.66667%; }
  .offset-sm-11 {
    margin-left: 45.83333%; }
  .offset-sm-12 {
    margin-left: 50%; }
  .offset-sm-13 {
    margin-left: 54.16667%; }
  .offset-sm-14 {
    margin-left: 58.33333%; }
  .offset-sm-15 {
    margin-left: 62.5%; }
  .offset-sm-16 {
    margin-left: 66.66667%; }
  .offset-sm-17 {
    margin-left: 70.83333%; }
  .offset-sm-18 {
    margin-left: 75%; }
  .offset-sm-19 {
    margin-left: 79.16667%; }
  .offset-sm-20 {
    margin-left: 83.33333%; }
  .offset-sm-21 {
    margin-left: 87.5%; }
  .offset-sm-22 {
    margin-left: 91.66667%; }
  .offset-sm-23 {
    margin-left: 95.83333%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 4.16667%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 12.5%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 20.83333%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 29.16667%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 37.5%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 45.83333%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-13 {
    flex: 0 0 auto;
    width: 54.16667%; }
  .col-md-14 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-15 {
    flex: 0 0 auto;
    width: 62.5%; }
  .col-md-16 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-17 {
    flex: 0 0 auto;
    width: 70.83333%; }
  .col-md-18 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-19 {
    flex: 0 0 auto;
    width: 79.16667%; }
  .col-md-20 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-21 {
    flex: 0 0 auto;
    width: 87.5%; }
  .col-md-22 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-23 {
    flex: 0 0 auto;
    width: 95.83333%; }
  .col-md-24 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 4.16667%; }
  .offset-md-2 {
    margin-left: 8.33333%; }
  .offset-md-3 {
    margin-left: 12.5%; }
  .offset-md-4 {
    margin-left: 16.66667%; }
  .offset-md-5 {
    margin-left: 20.83333%; }
  .offset-md-6 {
    margin-left: 25%; }
  .offset-md-7 {
    margin-left: 29.16667%; }
  .offset-md-8 {
    margin-left: 33.33333%; }
  .offset-md-9 {
    margin-left: 37.5%; }
  .offset-md-10 {
    margin-left: 41.66667%; }
  .offset-md-11 {
    margin-left: 45.83333%; }
  .offset-md-12 {
    margin-left: 50%; }
  .offset-md-13 {
    margin-left: 54.16667%; }
  .offset-md-14 {
    margin-left: 58.33333%; }
  .offset-md-15 {
    margin-left: 62.5%; }
  .offset-md-16 {
    margin-left: 66.66667%; }
  .offset-md-17 {
    margin-left: 70.83333%; }
  .offset-md-18 {
    margin-left: 75%; }
  .offset-md-19 {
    margin-left: 79.16667%; }
  .offset-md-20 {
    margin-left: 83.33333%; }
  .offset-md-21 {
    margin-left: 87.5%; }
  .offset-md-22 {
    margin-left: 91.66667%; }
  .offset-md-23 {
    margin-left: 95.83333%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 4.16667%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 20.83333%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 29.16667%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 45.83333%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-13 {
    flex: 0 0 auto;
    width: 54.16667%; }
  .col-lg-14 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-15 {
    flex: 0 0 auto;
    width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-17 {
    flex: 0 0 auto;
    width: 70.83333%; }
  .col-lg-18 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-19 {
    flex: 0 0 auto;
    width: 79.16667%; }
  .col-lg-20 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-21 {
    flex: 0 0 auto;
    width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-23 {
    flex: 0 0 auto;
    width: 95.83333%; }
  .col-lg-24 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 4.16667%; }
  .offset-lg-2 {
    margin-left: 8.33333%; }
  .offset-lg-3 {
    margin-left: 12.5%; }
  .offset-lg-4 {
    margin-left: 16.66667%; }
  .offset-lg-5 {
    margin-left: 20.83333%; }
  .offset-lg-6 {
    margin-left: 25%; }
  .offset-lg-7 {
    margin-left: 29.16667%; }
  .offset-lg-8 {
    margin-left: 33.33333%; }
  .offset-lg-9 {
    margin-left: 37.5%; }
  .offset-lg-10 {
    margin-left: 41.66667%; }
  .offset-lg-11 {
    margin-left: 45.83333%; }
  .offset-lg-12 {
    margin-left: 50%; }
  .offset-lg-13 {
    margin-left: 54.16667%; }
  .offset-lg-14 {
    margin-left: 58.33333%; }
  .offset-lg-15 {
    margin-left: 62.5%; }
  .offset-lg-16 {
    margin-left: 66.66667%; }
  .offset-lg-17 {
    margin-left: 70.83333%; }
  .offset-lg-18 {
    margin-left: 75%; }
  .offset-lg-19 {
    margin-left: 79.16667%; }
  .offset-lg-20 {
    margin-left: 83.33333%; }
  .offset-lg-21 {
    margin-left: 87.5%; }
  .offset-lg-22 {
    margin-left: 91.66667%; }
  .offset-lg-23 {
    margin-left: 95.83333%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 4.16667%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 12.5%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 20.83333%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 29.16667%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 37.5%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 45.83333%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-13 {
    flex: 0 0 auto;
    width: 54.16667%; }
  .col-xl-14 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-15 {
    flex: 0 0 auto;
    width: 62.5%; }
  .col-xl-16 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-17 {
    flex: 0 0 auto;
    width: 70.83333%; }
  .col-xl-18 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-19 {
    flex: 0 0 auto;
    width: 79.16667%; }
  .col-xl-20 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-21 {
    flex: 0 0 auto;
    width: 87.5%; }
  .col-xl-22 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-23 {
    flex: 0 0 auto;
    width: 95.83333%; }
  .col-xl-24 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 4.16667%; }
  .offset-xl-2 {
    margin-left: 8.33333%; }
  .offset-xl-3 {
    margin-left: 12.5%; }
  .offset-xl-4 {
    margin-left: 16.66667%; }
  .offset-xl-5 {
    margin-left: 20.83333%; }
  .offset-xl-6 {
    margin-left: 25%; }
  .offset-xl-7 {
    margin-left: 29.16667%; }
  .offset-xl-8 {
    margin-left: 33.33333%; }
  .offset-xl-9 {
    margin-left: 37.5%; }
  .offset-xl-10 {
    margin-left: 41.66667%; }
  .offset-xl-11 {
    margin-left: 45.83333%; }
  .offset-xl-12 {
    margin-left: 50%; }
  .offset-xl-13 {
    margin-left: 54.16667%; }
  .offset-xl-14 {
    margin-left: 58.33333%; }
  .offset-xl-15 {
    margin-left: 62.5%; }
  .offset-xl-16 {
    margin-left: 66.66667%; }
  .offset-xl-17 {
    margin-left: 70.83333%; }
  .offset-xl-18 {
    margin-left: 75%; }
  .offset-xl-19 {
    margin-left: 79.16667%; }
  .offset-xl-20 {
    margin-left: 83.33333%; }
  .offset-xl-21 {
    margin-left: 87.5%; }
  .offset-xl-22 {
    margin-left: 91.66667%; }
  .offset-xl-23 {
    margin-left: 95.83333%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 4.16667%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 12.5%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 20.83333%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 29.16667%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 37.5%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 45.83333%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-13 {
    flex: 0 0 auto;
    width: 54.16667%; }
  .col-xxl-14 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-15 {
    flex: 0 0 auto;
    width: 62.5%; }
  .col-xxl-16 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-17 {
    flex: 0 0 auto;
    width: 70.83333%; }
  .col-xxl-18 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-19 {
    flex: 0 0 auto;
    width: 79.16667%; }
  .col-xxl-20 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-21 {
    flex: 0 0 auto;
    width: 87.5%; }
  .col-xxl-22 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-23 {
    flex: 0 0 auto;
    width: 95.83333%; }
  .col-xxl-24 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 4.16667%; }
  .offset-xxl-2 {
    margin-left: 8.33333%; }
  .offset-xxl-3 {
    margin-left: 12.5%; }
  .offset-xxl-4 {
    margin-left: 16.66667%; }
  .offset-xxl-5 {
    margin-left: 20.83333%; }
  .offset-xxl-6 {
    margin-left: 25%; }
  .offset-xxl-7 {
    margin-left: 29.16667%; }
  .offset-xxl-8 {
    margin-left: 33.33333%; }
  .offset-xxl-9 {
    margin-left: 37.5%; }
  .offset-xxl-10 {
    margin-left: 41.66667%; }
  .offset-xxl-11 {
    margin-left: 45.83333%; }
  .offset-xxl-12 {
    margin-left: 50%; }
  .offset-xxl-13 {
    margin-left: 54.16667%; }
  .offset-xxl-14 {
    margin-left: 58.33333%; }
  .offset-xxl-15 {
    margin-left: 62.5%; }
  .offset-xxl-16 {
    margin-left: 66.66667%; }
  .offset-xxl-17 {
    margin-left: 70.83333%; }
  .offset-xxl-18 {
    margin-left: 75%; }
  .offset-xxl-19 {
    margin-left: 79.16667%; }
  .offset-xxl-20 {
    margin-left: 83.33333%; }
  .offset-xxl-21 {
    margin-left: 87.5%; }
  .offset-xxl-22 {
    margin-left: 91.66667%; }
  .offset-xxl-23 {
    margin-left: 95.83333%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: #0a58ca; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6;
      isolation: isolate; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas-header {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas-header {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas-header {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas-header {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas-header {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas-header {
    display: none; }
  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none; }
  .navbar-expand .offcanvas-top,
  .navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0; }
  .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }

@font-face {
  font-family: "Lovelo Black";
  src: url("fonts/Lovelo Black.otf") format("opentype"); }

@font-face {
  font-family: "Lovelo Line Bold";
  src: url("fonts/Lovelo Line Bold.otf") format("opentype"); }

@font-face {
  font-family: "Lovelo Line Light";
  src: url("fonts/Lovelo Line Light.otf") format("opentype"); }

@supports (-webkit-touch-callout: none) {
  .selector {
    background-attachment: scroll; } }

@supports not (-webkit-touch-callout: none) {
  .selector {
    background-attachment: fixed; } }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  color: #404244;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 1.4rem;
  font-weight: 400;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
  font-size: 14px; }


.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  padding-left: 0;
  padding-right: 0; }

.site-container {
  padding-top: 40px;
  padding-bottom: 80px; }
  @media only screen and (max-width: 1200px) {
    .site-container {
      padding-bottom: 100px; } }
  @media only screen and (max-width: 990px) {
    .site-container {
      padding-bottom: 240px;
      padding-top: 100px; } }
  .site-container #connect {
    background-color: #323A3A;
    height: 34px;
    top: 0;
    position: fixed;
    z-index: 999999; }
    @media only screen and (max-width: 990px) {
      .site-container #connect > .container {
        margin: 0;
        padding: 0; } }
    .site-container #connect ul {
      text-align: right;
      margin: 0; }
      .site-container #connect ul li {
        display: inline-block;
        padding: 6px 20px; }
        @media only screen and (max-width: 990px) {
          .site-container #connect ul li {
            /*display:block;*/ } }
        .site-container #connect ul li a {
          text-decoration: none;
          font-size: 15px; }
      .site-container #connect ul li.inscription {
        background-color: #FF4E00; }
        .site-container #connect ul li.inscription a {
          font-weight: 900;
          font-size: 15px;
          text-transform: uppercase;
          color: white;
          cursor: pointer; }
        @media only screen and (max-width: 990px) {
          .site-container #connect ul li.inscription {
            display: none; } }
      .site-container #connect ul li.contact a {
        font-weight: 900;
        color: #72CFAA;
        cursor: pointer; }
      .site-container #connect ul li.connexion a {
        color: white; }
  .site-container header {
    position: fixed;
    z-index: 9999999;
    top: 34px;
    background-color: white; }
    @media only screen and (max-width: 990px) {
      .site-container header {
        height: 66px;
        background-color: #323A3A; }
        .site-container header > .container {
          padding: 0; } }
    .site-container header .navbar {
      padding: 0;
      margin: 0; }
      .site-container header .navbar .navbar-brand {
        position: absolute;
        top: -40px;
        width: 110px; }
        @media only screen and (max-width: 990px) {
          .site-container header .navbar .navbar-brand {
            width: 90px;
            margin-right: 0; } }
        .site-container header .navbar .navbar-brand img {
          padding: 16px;
          background-color: white;
          max-width: 100%; }
        .site-container header .navbar .navbar-brand .signature {
          position: absolute;
          top: 16px;
          left: 118px; }
          @media only screen and (max-width: 990px) {
            .site-container header .navbar .navbar-brand .signature {
              top: unset;
              bottom: 5px;
              left: 95px; } }
          .site-container header .navbar .navbar-brand .signature .sport {
            font-weight: 500;
            color: white;
            font-style: normal;
            font-size: 12px; }
            @media only screen and (max-width: 990px) {
              .site-container header .navbar .navbar-brand .signature .sport {
                border: none;
                margin: 0;
                padding: 0; } }
          .site-container header .navbar .navbar-brand .signature .ouverture {
            font-size: 12px;
            font-weight: 300;
            font-style: italic;
            color: #72CFAA;
            margin-left: 5px; }
            @media only screen and (max-width: 990px) {
              .site-container header .navbar .navbar-brand .signature .ouverture {
                margin: 0;
                margin-top: -5px;
                font-weight: 300;
                font-style: italic;
                color: #72CFAA;
                display: block; } }
      .site-container header .navbar .navbar-toggler {
        position: absolute;
        right: 5px;
        top: 20px;
        border: none; }
        .site-container header .navbar .navbar-toggler .navbar-toggler-icon {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 78, 0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
      .site-container header .navbar #navbarNavDropdown {
        padding-left: 150px; }
        @media only screen and (max-width: 990px) {
          .site-container header .navbar #navbarNavDropdown {
            position: absolute;
            top: 65px;
            left: 0;
            padding: 0 20px 20px !important;
            background-color: white;
            width: 100%;
            z-index: 2; } }
        @media only screen and (max-width: 990px) {
          .site-container header .navbar #navbarNavDropdown .navbar-nav li {
            text-align: center; } }
        .site-container header .navbar #navbarNavDropdown .navbar-nav li a {
          color: #034E42;
          font-size: 15px;
          font-weight: 500;
          text-transform: uppercase;
          text-decoration: none;
          position: relative;
          padding: 10px 0 6px;
          display: block; }
          @media only screen and (max-width: 990px) {
            .site-container header .navbar #navbarNavDropdown .navbar-nav li a {
              font-size: 18px;
              padding: 14px 0 6px; } }
        .site-container header .navbar #navbarNavDropdown .navbar-nav li a:after {
          width: 16px;
          height: 8px;
          content: '';
          background-color: #2E9E71;
          position: absolute;
          bottom: -4px;
          left: 0;
          transition-duration: 0.5s; }
          @media only screen and (max-width: 990px) {
            .site-container header .navbar #navbarNavDropdown .navbar-nav li a:after {
              width: 20%;
              left: 40%;
              height: 4px; } }
        .site-container header .navbar #navbarNavDropdown .navbar-nav li a:hover:after {
          width: 50px; }
  .site-container .site-content a, .site-container .site-content a:hover {
    color: #404244;
    text-decoration: none; }
  .site-container .site-content img {
    max-width: 100%; }
  .site-container footer #newsletter {
    background: linear-gradient(to bottom, #034E42 0%, #2E9E71 100%);
    padding-top: 40px;
    padding-bottom: 40px; }
    @media only screen and (max-width: 990px) {
      .site-container footer #newsletter {
        text-align: center;
        padding-right: 15px;
        padding-left: 15px; } }
    .site-container footer #newsletter .text {
      font-size: 18px;
      color: white;
      margin: 0;
      padding: 0; }
      .site-container footer #newsletter .text p {
        padding-left: 10px;
        margin: 0;
        margin-bottom: 10px; }
        @media only screen and (max-width: 990px) {
          .site-container footer #newsletter .text p {
            padding-left: 0; } }
        .site-container footer #newsletter .text p strong {
          font-family: 'Lovelo Line Light','Comfortaa', sans-serif;
          font-size: 24px; }
    .site-container footer #newsletter .text-2 p {
      font-size: 14px;
      margin: 0 !important; }
    .site-container footer #newsletter .form {
      padding-top: 8px; }
      .site-container footer #newsletter .form input[type=text] {
        border: none;
        padding: 5px 10px;
        margin-top: -2px; }
      .site-container footer #newsletter .form button {
        font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
        font-size: 22px;
        background: transparent;
        border: none;
        color: white;
        margin-left: 15px;
        background: url("img/fleche_blanc.png") right 0 no-repeat;
        background-size: contain;
        height: 50px;
        padding-right: 60px; }
    .site-container footer #newsletter .nf-form-cont {
      padding-top: 8px; }
      .site-container footer #newsletter .nf-form-cont nf-field {
        float: left;
        width: 50%;
        height: auto; }
        .site-container footer #newsletter .nf-form-cont nf-field .nf-field-container {
          margin: 0; }
      .site-container footer #newsletter .nf-form-cont input[type=email] {
        border: none;
        padding: 5px 10px;
        margin-top: -2px;
        height: auto !important;
        font-size: 14px; }
      .site-container footer #newsletter .nf-form-cont .nf-field-element input[type=button] {
        margin-top: -10px;
        font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
        font-size: 22px;
        background: transparent;
        border: none;
        color: white;
        margin-left: 15px;
        background: url("img/fleche_blanc.png") right 0 no-repeat;
        background-size: contain;
        height: 50px;
        padding-right: 60px; }
      .site-container footer #newsletter .nf-form-cont .nf-pass.field-wrap .nf-field-element::after {
        top: -10px;
        right: 5px; }
      .site-container footer #newsletter .nf-form-cont .nf-response-msg p {
        font-weight: bold;
        color: white; }
      .site-container footer #newsletter .nf-form-cont .nf-error.field-wrap .nf-field-element::after {
        height: 28px;
        width: 30px;
        line-height: 30px;
        top: 0;
        right: 2px; }
      .site-container footer #newsletter .nf-form-cont .nf-error .nf-error-msg {
        color: white; }
      .site-container footer #newsletter .nf-form-cont .nf-error-msg,
      .site-container footer #newsletter .nf-form-cont .nf-after-form-content {
        display: none !important; }
  .site-container footer #menuFooter {
    padding-top: 20px;
    background-color: white; }
    .site-container footer #menuFooter ul {
      margin: 0;
      padding: 0; }
      .site-container footer #menuFooter ul li {
        display: inline-block;
        font-size: 12px;
        font-weight: 300;
        padding: 0 5px; }
        @media only screen and (max-width: 756px) {
          .site-container footer #menuFooter ul li {
            display: block; } }
        .site-container footer #menuFooter ul li a {
          color: #404244;
          text-decoration: none;
          font-size: 12px;
          font-weight: 300;
          padding: 0 5px; }
  .site-container footer #footer_inscription {
    position: fixed;
    z-index: 999;
    bottom: 0;
    background-color: white; }
    .site-container footer #footer_inscription .philosophie {
      text-align: right;
      padding: 25px 90px 10px 0;
      background: url(img/footer_picto_valeurs.png) right 20px center no-repeat white;
      background-size: 60px;
      font-size: 14px;
      font-weight: bold;
      color: #FF4E00; }
      @media only screen and (max-width: 1200px) {
        .site-container footer #footer_inscription .philosophie {
          padding: 30px 90px 10px 0; } }
      @media only screen and (max-width: 990px) {
        .site-container footer #footer_inscription .philosophie {
          text-align: center;
          padding: 20px 90px 10px 0; } }
      @media only screen and (max-width: 768px) {
        .site-container footer #footer_inscription .philosophie {
          padding: 20px 90px 20px 30px;
          background-position: right 30px center; } }
      .site-container footer #footer_inscription .philosophie a {
        font-size: 14px;
        font-weight: bold;
        color: #FF4E00;
        text-decoration: none; }
    .site-container footer #footer_inscription .signature {
      background-color: #FF4E00;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center;
      color: white;
      font-family: 'Lovelo Line Bold','Comfortaa', sans-serif; }
      .site-container footer #footer_inscription .signature strong {
        font-size: 30px; }
      @media only screen and (max-width: 768px) {
        .site-container footer #footer_inscription .signature {
          padding-top: 5px;
          padding-bottom: 5px; } }
    .site-container footer #footer_inscription .inscription {
      background-color: #FF4E00;
      text-align: center; }
      .site-container footer #footer_inscription .inscription a {
        color: #FF4E00;
        font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
        font-size: 22px;
        display: block;
        height: 100%;
        width: fit-content;
        padding: 25px 60px 20px 10px;
        background: url("img/fleche_orange.png") right 5px center no-repeat;
        background-size: 40px;
        background-color: white;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer; }
        @media only screen and (max-width: 990px) {
          .site-container footer #footer_inscription .inscription a {
            width: 100%;
            background-position: right 20px center; } }

@media only screen and (min-width: 990px) {
  body.page-template-home header .navbar .navbar-brand {
    position: absolute;
    top: -50px;
    width: inherit; }
    body.page-template-home header .navbar .navbar-brand img {
      padding: 30px;
      background-color: white; }
    body.page-template-home header .navbar .navbar-brand .signature {
      position: unset; }
      body.page-template-home header .navbar .navbar-brand .signature .sport {
        font-weight: 500;
        color: white;
        font-style: normal;
        display: block;
        border-bottom: solid 1px white;
        margin-bottom: 6px;
        padding-bottom: 6px;
        margin-top: 10px;
        font-size: 25px; }
      body.page-template-home header .navbar .navbar-brand .signature .ouverture {
        font-size: 18px;
        font-weight: 300;
        font-style: italic;
        color: #72CFAA;
        display: block; } }

body.page-template-home header .navbar #navbarNavDropdown {
  padding-left: 250px; }

.v-align {
  display: table;
  height: 100%; }
  .v-align > div {
    z-index: 1;
    display: table-cell;
    vertical-align: middle; }

.gl-0 {
  padding-left: 0 !important; }

.gr-0 {
  padding-right: 0 !important; }

#modal_contact .modal-content {
  text-align: center;
  padding: 50px 20px;
  background: transparent; }
  #modal_contact .modal-content .container > .row > div {
    aspect-ratio: 1/1; }
    @media only screen and (max-width: 990px) {
      #modal_contact .modal-content .container > .row > div {
        aspect-ratio: inherit; } }
  #modal_contact .modal-content .titre {
    color: white;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 20px; }
  #modal_contact .modal-content a {
    font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
    color: black;
    font-size: 36px;
    font-weight: 700;
    text-decoration: none;
    line-height: 38px;
    position: relative;
    background: url(img/inscription_off.jpg) right bottom;
    background-size: cover;
    position: relative;
    width: 120%; }
    @media only screen and (max-width: 990px) {
      #modal_contact .modal-content a {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 80px; } }
    #modal_contact .modal-content a .inscription {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      bottom: 20px;
      right: 20px;
      color: white;
      font-weight: bold;
      display: block;
      font-size: 16px;
      padding: 0 48px 0 10px;
      background: url("img/fleche_blanc.png") no-repeat #FF4E00;
      background-position: right 5px top 50%;
      background-size: 30px;
      cursor: pointer; }
  #modal_contact .modal-content a:hover {
    background: url(img/inscription_on.jpg) right bottom;
    background-size: cover;
    color: white; }

/* HOME */
@media only screen and (max-width: 990px) {
  body.home .site-content {
    background: url("img/page_bckgrd.jpg") center 0; } }

body.home .site-content #home-top {
  background: url("img/header_bckgrd.jpg") center 0 no-repeat; }
  @media only screen and (max-width: 990px) {
    body.home .site-content #home-top {
      background: transparent; } }
  body.home .site-content #home-top #visuel-home {
    height: 651px; }
    @media only screen and (max-width: 990px) {
      body.home .site-content #home-top #visuel-home {
        height: auto;
        aspect-ratio: 1/0.355 !important; } }
    body.home .site-content #home-top #visuel-home a {
      display: block;
      height: 100%; }
    body.home .site-content #home-top #visuel-home img {
      width: 100%; }
  body.home .site-content #home-top #offres .offre {
    height: 375px;
    position: relative; }
    @media only screen and (max-width: 990px) {
      body.home .site-content #home-top #offres .offre {
        height: 315px;
        background: url("img/offre_mobile_bckgrd.jpg") center -36px; } }
    @media only screen and (min-width: 756px) and (max-width: 990px) {
      body.home .site-content #home-top #offres .offre {
        height: 290px; } }
    body.home .site-content #home-top #offres .offre .background {
      mix-blend-mode: multiply;
      -webkit-mix-blend-mode: multiply;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0; }
    body.home .site-content #home-top #offres .offre .content a {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      color: white;
      padding: 20px 20px;
      text-transform: uppercase;
      cursor: pointer; }
      body.home .site-content #home-top #offres .offre .content a .nom {
        font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
        font-weight: bold;
        font-size: 55px;
        padding: 20px 0;
        position: relative;
        padding-left: 50px;
        margin-bottom: 20px;
        height: 86px; }
        @media only screen and (max-width: 990px) {
          body.home .site-content #home-top #offres .offre .content a .nom {
            height: 70px;
            width: fit-content; } }
      body.home .site-content #home-top #offres .offre .content a .nom:before {
        content: '';
        background: url("img/chevron_blanc.png") 0 0 no-repeat;
        background-size: contain;
        height: 50px;
        width: 40px;
        display: block;
        position: absolute;
        top: 10px;
        left: 0; }
      body.home .site-content #home-top #offres .offre .content a .nom:after {
        content: '';
        height: 6px;
        width: 280px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 50px;
        background-color: white; }
        @media only screen and (max-width: 1200px) {
          body.home .site-content #home-top #offres .offre .content a .nom:after {
            width: 150px; } }
        @media only screen and (max-width: 990px) {
          body.home .site-content #home-top #offres .offre .content a .nom:after {
            width: 100%; } }
      body.home .site-content #home-top #offres .offre .content a .infos {
        /*padding-left:50px;
								background:url('img/fleche_blanc.png') right 5px top 15px no-repeat;
								background-size:20px;*/ }
        body.home .site-content #home-top #offres .offre .content a .infos h3, body.home .site-content #home-top #offres .offre .content a .infos .h3 {
          font-size: 20px;
          text-transform: normal; }
        @media only screen and (max-width: 1400px) {
          body.home .site-content #home-top #offres .offre .content a .infos {
            background-size: 80px; } }
        @media only screen and (max-width: 1200px) {
          body.home .site-content #home-top #offres .offre .content a .infos {
            background-size: 60px; } }
        @media only screen and (max-width: 990px) {
          body.home .site-content #home-top #offres .offre .content a .infos {
            padding-left: 50px;
            background-position: right 30px top 15px; } }
        @media only screen and (min-width: 756px) and (max-width: 990px) {
          body.home .site-content #home-top #offres .offre .content a .infos {
            background-size: 100px;
            background-position: right 34px top 2px; } }
        body.home .site-content #home-top #offres .offre .content a .infos .a_partir {
          font-size: 24px;
          display: block; }
        body.home .site-content #home-top #offres .offre .content a .infos .prix .montant {
          font-size: 110px;
          font-weight: 900;
          line-height: 120px;
          float: left;
          width: 43%; }
          @media only screen and (max-width: 1400px) {
            body.home .site-content #home-top #offres .offre .content a .infos .prix .montant {
              font-size: 100px;
              line-height: 100px;
              width: 48%; } }
          @media only screen and (max-width: 1200px) {
            body.home .site-content #home-top #offres .offre .content a .infos .prix .montant {
              font-size: 85px;
              width: 48%; } }
          @media only screen and (max-width: 990px) {
            body.home .site-content #home-top #offres .offre .content a .infos .prix .montant {
              width: 42%; } }
          @media only screen and (min-width: 756px) and (max-width: 990px) {
            body.home .site-content #home-top #offres .offre .content a .infos .prix .montant {
              font-size: 125px; } }
        body.home .site-content #home-top #offres .offre .content a .infos .prix .mois {
          font-size: 24px;
          float: left;
          width: 40%;
          padding-top: 13px;
          line-height: 51px; }
          @media only screen and (max-width: 1400px) {
            body.home .site-content #home-top #offres .offre .content a .infos .prix .mois {
              font-size: 22px;
              line-height: 40px; } }
          @media only screen and (max-width: 1200px) {
            body.home .site-content #home-top #offres .offre .content a .infos .prix .mois {
              font-size: 20px; } }
          @media only screen and (max-width: 990px) {
            body.home .site-content #home-top #offres .offre .content a .infos .prix .mois {
              width: 20%; } }
          body.home .site-content #home-top #offres .offre .content a .infos .prix .mois strong {
            font-size: 60px;
            font-weight: 300;
            display: block; }
            @media only screen and (max-width: 1400px) {
              body.home .site-content #home-top #offres .offre .content a .infos .prix .mois strong {
                font-size: 50px; } }
            @media only screen and (max-width: 1200px) {
              body.home .site-content #home-top #offres .offre .content a .infos .prix .mois strong {
                font-size: 40px; } }
        body.home .site-content #home-top #offres .offre .content a .infos .engagement {
          clear: both;
          display: block; }
          @media only screen and (max-width: 990px) {
            body.home .site-content #home-top #offres .offre .content a .infos .engagement {
              font-size: 13px; } }
        body.home .site-content #home-top #offres .offre .content a .infos .adhesion {
          position: absolute;
          bottom: 0;
          width: 90px;
          right: 50px;
          font-size: 18px;
          font-weight: 500;
          border-left: 3px solid white;
          padding-left: 12px;
          padding-bottom: 20px; }
          @media only screen and (max-width: 1400px) {
            body.home .site-content #home-top #offres .offre .content a .infos .adhesion {
              width: 90px;
              right: 40px;
              font-size: 16px;
              padding-bottom: 10px; } }
          @media only screen and (max-width: 1200px) {
            body.home .site-content #home-top #offres .offre .content a .infos .adhesion {
              width: 90px;
              right: 40px;
              font-size: 16px;
              padding-bottom: 10px; } }
          @media only screen and (min-width: 756px) and (max-width: 990px) {
            body.home .site-content #home-top #offres .offre .content a .infos .adhesion {
              width: 220px;
              right: 10px;
              padding-top: 9px; } }
          body.home .site-content #home-top #offres .offre .content a .infos .adhesion strong {
            font-size: 26px;
            font-weight: 900;
            display: block; }
            @media only screen and (max-width: 1400px) {
              body.home .site-content #home-top #offres .offre .content a .infos .adhesion strong {
                font-size: 20px; } }
            @media only screen and (max-width: 1200px) {
              body.home .site-content #home-top #offres .offre .content a .infos .adhesion strong {
                font-size: 20px; } }
            @media only screen and (min-width: 756px) and (max-width: 990px) {
              body.home .site-content #home-top #offres .offre .content a .infos .adhesion strong {
                display: inline-block; } }

body.home .site-content #salles {
  position: relative;
  min-height: 380px; }
  @media only screen and (max-width: 990px) {
    body.home .site-content #salles {
      min-height: 420px;
      background-color: white;
      padding-top: 40px; } }
  body.home .site-content #salles .container,
  body.home .site-content #salles .container .row {
    min-height: 300px; }
  body.home .site-content #salles .top {
    z-index: 2;
    /*position:relative;
				height:calc(50% + 14px);*/ }
    body.home .site-content #salles .top .titre {
      padding-left: 20px;
      padding-right: 20px;
      font-size: 40px;
      font-weight: 900;
      text-transform: uppercase;
      background-color: white;
      display: inline-block;
      color: #034E42;
      position: absolute;
      bottom: calc(50% - 14px);
      z-index: 2; }
      @media only screen and (max-width: 990px) {
        body.home .site-content #salles .top .titre {
          bottom: unset;
          position: relative;
          text-align: center;
          width: 100%; } }
    body.home .site-content #salles .top .lieux {
      position: absolute;
      bottom: calc(50% + 24px);
      left: 49%;
      z-index: 2; }
      @media only screen and (max-width: 1200px) {
        body.home .site-content #salles .top .lieux {
          left: 53%; } }
      @media only screen and (max-width: 990px) {
        body.home .site-content #salles .top .lieux {
          top: 35%;
          left: 37%; } }
      body.home .site-content #salles .top .lieux .salle {
        float: left;
        margin-left: -90px; }
        body.home .site-content #salles .top .lieux .salle a {
          position: relative;
          transform: rotate(-45deg);
          padding-left: 50px;
          width: fit-content;
          display: block;
          font-family: 'Lovelo Line light','Comfortaa', sans-serif;
          font-size: 25px;
          font-weight: 300;
          color: #034E42; }
          body.home .site-content #salles .top .lieux .salle a strong {
            font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
            font-weight: bold; }
        body.home .site-content #salles .top .lieux .salle a:before {
          content: '';
          height: 40px;
          width: 40px;
          display: block;
          background: url("img/salle_icone.png") 0 0 no-repeat;
          background-size: contain;
          position: absolute;
          top: 0;
          left: 0; }
  body.home .site-content #salles h3, body.home .site-content #salles .h3 {
    margin-top: 40%;
    font-family: 'Montserrat','Comfortaa', sans-serif;
    font-size: 14px; }
    @media only screen and (max-width: 1400px) {
      body.home .site-content #salles h3, body.home .site-content #salles .h3 {
        margin-top: 46%; } }
    @media only screen and (max-width: 1200px) {
      body.home .site-content #salles h3, body.home .site-content #salles .h3 {
        margin-top: 260px; } }
    @media only screen and (max-width: 990px) {
      body.home .site-content #salles h3, body.home .site-content #salles .h3 {
        margin-top: 210px; } }

body.home .site-content #salles:after {
  background: url("img/salle_bckgrd.png") right bottom no-repeat;
  background-size: contain;
  position: absolute;
  content: '';
  height: 100%;
  aspect-ratio: 1/0.69;
  z-index: 3;
  bottom: 0;
  right: 0; }
  @media only screen and (max-width: 1200px) {
    body.home .site-content #salles:after {
      height: 80%; } }
  @media only screen and (max-width: 990px) {
    body.home .site-content #salles:after {
      display: none; } }

body.home .site-content #salles:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 6px;
  top: 50%;
  background-color: #034E42;
  z-index: 1; }
  @media only screen and (max-width: 990px) {
    body.home .site-content #salles:before {
      top: 45%; } }
  @media only screen and (max-width: 990px) {
    body.home .site-content #salles:before {
      top: 52%; } }

body.home .site-content #small {
  background: url("img/small_bckgrd.jpg") 0 0 no-repeat;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 70px;
  position: relative; }
  @media only screen and (max-width: 990px) {
    body.home .site-content #small {
      background: url("img/small_mobile_bckgrd.jpg") right 0 no-repeat;
      background-size: cover; } }
  body.home .site-content #small * {
    color: white; }
  body.home .site-content #small a {
    font-size: 30px;
    font-weight: 300; }
    body.home .site-content #small a h2, body.home .site-content #small a .h2 {
      font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
      font-weight: bold;
      font-size: 65px; }
      @media only screen and (max-width: 990px) {
        body.home .site-content #small a h2, body.home .site-content #small a .h2 {
          width: 65%;
          font-size: 43px; } }
    body.home .site-content #small a h3, body.home .site-content #small a .h3 {
      margin: 30px 0;
      font-size: 14px;
      width: 70%; }
      @media only screen and (max-width: 990px) {
        body.home .site-content #small a h3, body.home .site-content #small a .h3 {
          width: 80%; } }
  body.home .site-content #small .appli {
    width: 500px;
    position: absolute;
    left: 47%;
    bottom: 15px;
    z-index: 999; }
    body.home .site-content #small .appli .img {
      width: 100%; }
    @media only screen and (max-width: 990px) {
      body.home .site-content #small .appli {
        position: relative;
        bottom: unset;
        left: unset;
        width: 80%;
        margin: 0 auto; } }
    @media only screen and (max-width: 768px) {
      body.home .site-content #small .appli {
        width: 100%; } }
    body.home .site-content #small .appli a {
      display: block;
      height: 100%; }

body.home .site-content #small:after {
  background: url("img/small_bckgrd_right.png") right bottom no-repeat;
  mix-blend-mode: hard-light;
  -webkit-mix-blend-mode: hard-light;
  background-size: contain;
  position: absolute;
  content: '';
  height: 100%;
  aspect-ratio: 1/0.69;
  z-index: 3;
  bottom: 0;
  right: 0;
  z-index: 998; }
  @media only screen and (max-width: 990px) {
    body.home .site-content #small:after {
      display: none; } }

body.home .site-content #indoorphine {
  border-top: solid 20px #077F6C;
  background: url("img/page_bckgrd.jpg") 0 0 no-repeat;
  background-size: cover;
  padding-top: 30px;
  padding-bottom: 55px;
  position: relative; }
  @media only screen and (max-width: 990px) {
    body.home .site-content #indoorphine {
      padding-bottom: 120px; } }
  body.home .site-content #indoorphine .titre {
    margin-left: 5%;
    margin-top: 10px; }
    @media only screen and (max-width: 990px) {
      body.home .site-content #indoorphine .titre {
        margin-left: 0; } }
  body.home .site-content #indoorphine h2, body.home .site-content #indoorphine .h2 {
    font-weight: 900;
    color: #034E42;
    clear: both;
    font-size: 24px; }
  body.home .site-content #indoorphine h3, body.home .site-content #indoorphine .h3 {
    font-weight: 500;
    font-size: 14px; }
  body.home .site-content #indoorphine .lien {
    font-size: 25px;
    text-align: right;
    text-transform: uppercase;
    margin-top: 20px; }
  body.home .site-content #indoorphine .img {
    content: '';
    width: 38%;
    position: absolute;
    bottom: 0;
    left: 40px; }
    @media only screen and (max-width: 850px) {
      body.home .site-content #indoorphine .img {
        width: 60%; } }

body.home .site-content #temoignages {
  background: url("img/temoignages_bckgrd.jpg") 0 0;
  background-size: cover;
  padding-top: 70px;
  position: relative; }
  body.home .site-content #temoignages * {
    color: white; }
  body.home .site-content #temoignages h2, body.home .site-content #temoignages .h2 {
    font-style: italic;
    font-size: 30px;
    line-height: 32px;
    position: relative;
    text-align: right;
    margin-left: 30%;
    margin-right: 30%;
    margin-bottom: 50px; }
    @media only screen and (max-width: 1200px) {
      body.home .site-content #temoignages h2, body.home .site-content #temoignages .h2 {
        margin-left: 25px;
        margin-right: 115px; } }
    @media only screen and (max-width: 990px) {
      body.home .site-content #temoignages h2, body.home .site-content #temoignages .h2 {
        margin-left: 25px;
        margin-right: 115px;
        font-size: 25px; } }
    body.home .site-content #temoignages h2 img, body.home .site-content #temoignages .h2 img {
      position: absolute;
      width: 90px;
      top: 10px;
      right: -110px; }
      @media only screen and (max-width: 990px) {
        body.home .site-content #temoignages h2 img, body.home .site-content #temoignages .h2 img {
          width: 50px;
          right: -60px; } }
  body.home .site-content #temoignages .temoignage {
    padding-left: 8px;
    padding-right: 8px; }
    body.home .site-content #temoignages .temoignage .content {
      aspect-ratio: 1/1.1;
      background-size: cover !important;
      position: relative; }
      body.home .site-content #temoignages .temoignage .content .img {
        mix-blend-mode: luminosity;
        -webkit-mix-blend-mode: luminosity; }
        @media only screen and (max-width: 768px) {
          body.home .site-content #temoignages .temoignage .content .img img {
            width: 100%; } }
      body.home .site-content #temoignages .temoignage .content .text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        background-color: rgba(0, 0, 0, 0.7); }
        body.home .site-content #temoignages .temoignage .content .text strong {
          font-weight: 900;
          text-transform: uppercase; }
  body.home .site-content #temoignages .temoignage:hover .img {
    mix-blend-mode: normal;
    -webkit-mix-blend-mode: normal; }

/* PAGE STANDARD */
body.page-template-default .site-container #content {
  background: url("img/page_header_bckgrd.jpg") center 0 no-repeat;
  padding-top: 36px; }
  body.page-template-default .site-container #content .page-content {
    background: url("img/page_bckgrd.jpg") center 0 white; }
    body.page-template-default .site-container #content .page-content > .container {
      background-color: white; }
      body.page-template-default .site-container #content .page-content > .container > .row {
        box-shadow: -20px 0 20px -20px #333, 20px 0 20px -20px #333;
        padding-top: 40px;
        padding-bottom: 80px; }
        body.page-template-default .site-container #content .page-content > .container > .row h1, body.page-template-default .site-container #content .page-content > .container > .row .h1 {
          font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
          font-size: 30px;
          color: #FF4E00;
          text-align: center; }
        body.page-template-default .site-container #content .page-content > .container > .row h2, body.page-template-default .site-container #content .page-content > .container > .row .h2 {
          font-size: 20px;
          color: #FF4E00;
          font-weight: 700; }
        body.page-template-default .site-container #content .page-content > .container > .row .content {
          padding-top: 30px; }

/* PAGE INTERIEURE BLOCK */
body.page-template-page_interieure .site-container #content {
  background: url("img/page_header_bckgrd.jpg") center 0 no-repeat;
  padding-top: 36px; }
  body.page-template-page_interieure .site-container #content .page-block {
    background: url("img/page_bckgrd.jpg") center 0 white; }
    body.page-template-page_interieure .site-container #content .page-block > .container > .row {
      box-shadow: -20px 0 20px -20px #333, 20px 0 20px -20px #333; }
    body.page-template-page_interieure .site-container #content .page-block .block-page {
      padding: 30px 40px;
      background: white; }
      body.page-template-page_interieure .site-container #content .page-block .block-page .content a, body.page-template-page_interieure .site-container #content .page-block .block-page .content a:visited {
        text-decoration: underline !important; }
      body.page-template-page_interieure .site-container #content .page-block .block-page .content h1, body.page-template-page_interieure .site-container #content .page-block .block-page .content .h1 {
        text-align: right;
        font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
        font-weight: 700;
        font-size: 30px;
        color: #FF4E00;
        position: relative;
        padding-bottom: 20px; }
        body.page-template-page_interieure .site-container #content .page-block .block-page .content h1 strong, body.page-template-page_interieure .site-container #content .page-block .block-page .content .h1 strong {
          font-size: 65px; }
      body.page-template-page_interieure .site-container #content .page-block .block-page .content h1:after, body.page-template-page_interieure .site-container #content .page-block .block-page .content .h1:after {
        content: '';
        height: 6px;
        width: 235px;
        background-color: #FF4E00;
        position: absolute;
        bottom: 0;
        right: 0; }
      body.page-template-page_interieure .site-container #content .page-block .block-page .content h2, body.page-template-page_interieure .site-container #content .page-block .block-page .content .h2 {
        background: url("img/chevron_orange.png") 0 center no-repeat white;
        font-size: 16px;
        font-weight: 700;
        padding: 20px 20px 20px 90px;
        margin: 20px 0;
        text-transform: uppercase;
        position: relative;
        right: -110px;
        width: 110%; }
      body.page-template-page_interieure .site-container #content .page-block .block-page .content h3, body.page-template-page_interieure .site-container #content .page-block .block-page .content .h3 {
        font-size: 14px;
        text-align: right; }
        body.page-template-page_interieure .site-container #content .page-block .block-page .content h3 em, body.page-template-page_interieure .site-container #content .page-block .block-page .content .h3 em {
          font-style: normal;
          color: #FF4E00;
          font-weight: 700; }
      body.page-template-page_interieure .site-container #content .page-block .block-page .visuel img {
        width: 100%; }
    body.page-template-page_interieure .site-container #content .page-block .cta {
      margin-top: 30px; }
      body.page-template-page_interieure .site-container #content .page-block .cta a {
        display: block;
        background: url("img/chevron_vert.png") 0 0 no-repeat;
        background-size: 50px;
        padding: 0 0 0 60px;
        color: #034E42;
        font-weight: bold;
        font-size: 26px; }
        body.page-template-page_interieure .site-container #content .page-block .cta a .type {
          font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
          font-weight: 700;
          color: #034E42;
          margin-top: 10px;
          display: block; }
        body.page-template-page_interieure .site-container #content .page-block .cta a strong {
          font-weight: 700;
          background-color: #077F6C;
          color: white;
          display: inline-block;
          padding: 6px 15px;
          margin-top: 10px;
          -webkit-border-top-right-radius: 10px;
          -webkit-border-bottom-right-radius: 10px;
          -moz-border-radius-topright: 10px;
          -moz-border-radius-bottomright: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          position: relative; }
        body.page-template-page_interieure .site-container #content .page-block .cta a strong:after {
          content: '';
          height: 40px;
          width: 40px;
          position: absolute;
          top: -2px;
          right: -50px;
          background: url("img/fleche_vert.png") 0 0 no-repeat;
          background-size: cover; }
    body.page-template-page_interieure .site-container #content .page-block .cta-decouverte {
      margin-left: 20px;
      margin-top: 40px;
      padding: 8px 40px 8px 15px;
      width: calc(100% - 60px);
      border: solid 3px #FF4E00;
      border-radius: 30px;
      font-size: 22px;
      font-weight: bold;
      background: url("img/fleche_orange.png") no-repeat;
      background-position: right 5px top 1px;
      background-size: 36px; }
      @media only screen and (max-width: 990px) {
        body.page-template-page_interieure .site-container #content .page-block .cta-decouverte {
          background-position: right 5px top 50%;
          width: calc(100% - 40px); } }
      body.page-template-page_interieure .site-container #content .page-block .cta-decouverte a {
        color: #FF4E00; }
        body.page-template-page_interieure .site-container #content .page-block .cta-decouverte a strong {
          font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
          font-weight: 700; }
    body.page-template-page_interieure .site-container #content .page-block .cta-contact {
      color: #FF4E00;
      font-weight: bold;
      font-size: 22px;
      margin-top: 30px;
      margin-left: 10px; }
      body.page-template-page_interieure .site-container #content .page-block .cta-contact .btn_contact, body.page-template-page_interieure .site-container #content .page-block .cta-contact .btn_tel, body.page-template-page_interieure .site-container #content .page-block .cta-contact .btn_email, body.page-template-page_interieure .site-container #content .page-block .cta-contact .btn_web {
        text-decoration: none;
        display: inline-block;
        height: 100%;
        color: #FF4E00;
        font-size: 22px;
        font-weight: bold; }
      body.page-template-page_interieure .site-container #content .page-block .cta-contact .btn_contact {
        border: solid 2px #FF4E00;
        border-radius: 20px;
        padding: 6px 12px;
        margin-left: 15px; }
      body.page-template-page_interieure .site-container #content .page-block .cta-contact .btn_tel, body.page-template-page_interieure .site-container #content .page-block .cta-contact .btn_email, body.page-template-page_interieure .site-container #content .page-block .cta-contact .btn_web {
        text-indent: -9000px;
        background: url(img/tel.svg) center center no-repeat;
        width: 30px;
        height: 30px;
        background-size: contain;
        top: -5px;
        position: relative;
        margin-left: 10px; }
      body.page-template-page_interieure .site-container #content .page-block .cta-contact .btn_email {
        background-image: url(img/email.svg);
        width: 37px; }
      body.page-template-page_interieure .site-container #content .page-block .cta-contact .btn_web {
        background-image: url(img/web.svg); }
    body.page-template-page_interieure .site-container #content .page-block .block-page.texte-visuel .content {
      padding-right: 60px !important; }
    body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content {
      padding-left: 60px !important; }
      body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content h1, body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content .h1 {
        text-align: left; }
      body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content h1:after, body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content .h1:after {
        left: 0; }
      body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content h2, body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content .h2 {
        background-position: 20px center;
        padding: 20px 20px 20px 110px;
        left: -160px; }
      body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content h3, body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content .h3 {
        text-align: left; }

/*PAGE OFFRES */
body.page-template-page_offres #content {
  background: url(img/page_bckgrd.jpg) center 0; }

body.page-template-page_offres .page-offres {
  background: url(img/offre_bckgrd.jpg) center 0 no-repeat;
  padding-top: 97px; }
  @media only screen and (max-width: 990px) {
    body.page-template-page_offres .page-offres {
      padding-top: 50px; } }
  body.page-template-page_offres .page-offres .tab-offres {
    padding-top: 6px;
    padding-bottom: 20px; }
    body.page-template-page_offres .page-offres .tab-offres .row.first {
      margin-bottom: 6px; }
      body.page-template-page_offres .page-offres .tab-offres .row.first .offre {
        position: relative;
        color: white; }
        body.page-template-page_offres .page-offres .tab-offres .row.first .offre .background {
          mix-blend-mode: multiply;
          -webkit-mix-blend-mode: multiply;
          position: absolute;
          height: 100%;
          width: calc(100% - 6px);
          top: 0;
          left: 3x;
          z-index: 0; }
        body.page-template-page_offres .page-offres .tab-offres .row.first .offre .content {
          position: relative;
          z-index: 1; }
          body.page-template-page_offres .page-offres .tab-offres .row.first .offre .content .nom {
            font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
            font-weight: bold;
            font-size: 50px;
            padding: 32px 0;
            position: relative;
            margin-bottom: 10px;
            border-bottom: solid 6px white;
            width: fit-content;
            margin: 0 auto; }
            @media only screen and (max-width: 1400px) {
              body.page-template-page_offres .page-offres .tab-offres .row.first .offre .content .nom {
                font-size: 40px; } }
            @media only screen and (max-width: 1200px) {
              body.page-template-page_offres .page-offres .tab-offres .row.first .offre .content .nom {
                font-size: 38px; } }
          body.page-template-page_offres .page-offres .tab-offres .row.first .offre .content .nom:before {
            content: '';
            background: url("img/chevron_blanc.png") 0 0 no-repeat;
            background-size: contain;
            height: 50px;
            width: 40px;
            display: block;
            position: absolute;
            top: 22px;
            left: -50px; }
          body.page-template-page_offres .page-offres .tab-offres .row.first .offre .content .nom.asterisque:after {
            content: '*';
            display: block;
            position: absolute;
            top: 37px;
            right: -25px; }
          body.page-template-page_offres .page-offres .tab-offres .row.first .offre .content .infos {
            padding-left: 50px;
            background: url("img/fleche_blanc.png") right 5px top 15px no-repeat; }
            @media only screen and (max-width: 1200px) {
              body.page-template-page_offres .page-offres .tab-offres .row.first .offre .content .infos {
                padding-left: 30px; } }
            body.page-template-page_offres .page-offres .tab-offres .row.first .offre .content .infos .prix .montant {
              font-size: 110px;
              font-weight: 900;
              line-height: 120px;
              float: left;
              width: 42%; }
              @media only screen and (max-width: 1400px) {
                body.page-template-page_offres .page-offres .tab-offres .row.first .offre .content .infos .prix .montant {
                  font-size: 100px; } }
              @media only screen and (max-width: 1400px) {
                body.page-template-page_offres .page-offres .tab-offres .row.first .offre .content .infos .prix .montant {
                  font-size: 90px;
                  width: inherit; } }
            body.page-template-page_offres .page-offres .tab-offres .row.first .offre .content .infos .prix .mois {
              font-size: 24px;
              float: left;
              text-align: right;
              width: 40%;
              padding-top: 13px;
              line-height: 51px; }
              @media only screen and (max-width: 1200px) {
                body.page-template-page_offres .page-offres .tab-offres .row.first .offre .content .infos .prix .mois {
                  font-size: 20px;
                  width: inherit; } }
              body.page-template-page_offres .page-offres .tab-offres .row.first .offre .content .infos .prix .mois strong {
                font-size: 60px;
                font-weight: 300;
                display: block; }
                @media only screen and (max-width: 1200px) {
                  body.page-template-page_offres .page-offres .tab-offres .row.first .offre .content .infos .prix .mois strong {
                    font-size: 40px; } }
    body.page-template-page_offres .page-offres .tab-offres .row .offre {
      text-align: center;
      padding-right: 3px;
      padding-left: 3px;
      font-size: 20px; }
      body.page-template-page_offres .page-offres .tab-offres .row .offre .true {
        text-indent: -9000px;
        background: url(img/true_vert_0.png) center 0 no-repeat;
        background-size: contain;
        height: 50px;
        width: 50px;
        margin: 15px auto; }
      body.page-template-page_offres .page-offres .tab-offres .row .offre .true.true_1 {
        background-image: url(img/true_vert_1.png); }
      body.page-template-page_offres .page-offres .tab-offres .row .offre .true.true_2 {
        background-image: url(img/true_vert_2.png); }
      body.page-template-page_offres .page-offres .tab-offres .row .offre .false {
        height: 50px;
        width: 50px;
        margin: 30px auto; }
    body.page-template-page_offres .page-offres .tab-offres .row .offre.data .v-align {
      width: 100%; }
    body.page-template-page_offres .page-offres .tab-offres .row .offre.data:nth-child(1) > div {
      color: #404244;
      text-align: left !important;
      border-top: solid 4px #404244;
      padding-top: 15px;
      padding-bottom: 10px; }
    body.page-template-page_offres .page-offres .tab-offres .row .offre.data:nth-child(2) > div {
      color: #2e9e71;
      border-top: solid 4px #2e9e71;
      text-transform: uppercase; }
    body.page-template-page_offres .page-offres .tab-offres .row .offre.data:nth-child(3) > div {
      color: #077f6c;
      border-top: solid 4px #077f6c; }
    body.page-template-page_offres .page-offres .tab-offres .row .offre.data:nth-child(4) > div {
      color: #034e42;
      border-top: solid 4px #034e42; }
    body.page-template-page_offres .page-offres .tab-offres .row h2, body.page-template-page_offres .page-offres .tab-offres .row .h2 {
      font-size: 22px;
      font-weight: 900;
      text-transform: uppercase;
      margin: 0; }
      body.page-template-page_offres .page-offres .tab-offres .row h2 strong, body.page-template-page_offres .page-offres .tab-offres .row .h2 strong {
        font-size: 30px; }
    body.page-template-page_offres .page-offres .tab-offres .row h3, body.page-template-page_offres .page-offres .tab-offres .row .h3 {
      font-size: 14px; }
    body.page-template-page_offres .page-offres .tab-offres .row:last-child .offre.data:nth-child(1) > div {
      border-bottom: solid 4px #404244; }
    body.page-template-page_offres .page-offres .tab-offres .row:last-child .offre.data:nth-child(2) > div {
      border-bottom: solid 4px #2e9e71; }
    body.page-template-page_offres .page-offres .tab-offres .row:last-child .offre.data:nth-child(3) > div {
      border-bottom: solid 4px #077f6c; }
    body.page-template-page_offres .page-offres .tab-offres .row:last-child .offre.data:nth-child(4) > div {
      border-bottom: solid 4px #034e42; }
    body.page-template-page_offres .page-offres .tab-offres .frais_adhesion .offre {
      font-size: 50px; }
  body.page-template-page_offres .page-offres .tab-inscription .row {
    margin-bottom: 6px; }
    body.page-template-page_offres .page-offres .tab-inscription .row .sportif {
      background: url("img/sportif.png") 0 bottom no-repeat;
      background-size: contain; }
    body.page-template-page_offres .page-offres .tab-inscription .row .offre {
      position: relative;
      color: white; }
      body.page-template-page_offres .page-offres .tab-inscription .row .offre .background {
        mix-blend-mode: multiply;
        -webkit-mix-blend-mode: multiply;
        position: absolute;
        height: 100%;
        width: calc(100% - var(--bs-gutter-x) * .5);
        top: 0;
        left: 3x;
        z-index: 0; }
      body.page-template-page_offres .page-offres .tab-inscription .row .offre .content {
        position: relative;
        z-index: 1;
        padding-left: 90px;
        padding-top: 5px;
        padding-bottom: 20px; }
        body.page-template-page_offres .page-offres .tab-inscription .row .offre .content .nom {
          font-size: 20px;
          font-weight: bold;
          line-height: 40px; }
          body.page-template-page_offres .page-offres .tab-inscription .row .offre .content .nom strong {
            font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
            font-weight: bold;
            font-size: 45px;
            display: block; }
        @media only screen and (max-width: 1400px) {
          body.page-template-page_offres .page-offres .tab-inscription .row .offre .content .nom strong {
            font-size: 37px; } }
        @media only screen and (max-width: 1200px) {
          body.page-template-page_offres .page-offres .tab-inscription .row .offre .content .nom {
            font-size: 20px; }
            body.page-template-page_offres .page-offres .tab-inscription .row .offre .content .nom strong {
              font-size: 32px; } }
        body.page-template-page_offres .page-offres .tab-inscription .row .offre .content .inscription {
          display: block;
          position: absolute;
          bottom: -30px;
          right: -20px;
          background-color: #FF4E00;
          padding: 4px 8px; }
          body.page-template-page_offres .page-offres .tab-inscription .row .offre .content .inscription a {
            color: white;
            font-weight: bold;
            display: block;
            height: 100%;
            padding: 3px 34px 3px 4px;
            background: url("img/fleche_blanc.png") right 0 no-repeat;
            background-size: contain;
            cursor: pointer; }
      body.page-template-page_offres .page-offres .tab-inscription .row .offre .content:before {
        content: '';
        background: url("img/chevron_blanc.png") 0 0 no-repeat;
        background-size: contain;
        height: 55px;
        width: 55px;
        display: block;
        position: absolute;
        top: 20px;
        left: 20px; }
    body.page-template-page_offres .page-offres .tab-inscription .row .options {
      margin-top: 20px;
      margin-top: 80px; }
      body.page-template-page_offres .page-offres .tab-inscription .row .options .row {
        margin-top: 10px; }
      body.page-template-page_offres .page-offres .tab-inscription .row .options .row > * {
        padding-left: 4px;
        padding-right: 4px; }
      body.page-template-page_offres .page-offres .tab-inscription .row .options .titre {
        font-size: 30px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px; }
        body.page-template-page_offres .page-offres .tab-inscription .row .options .titre strong {
          font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
          font-weight: bold;
          font-size: 45px;
          color: #2E9E71; }
      body.page-template-page_offres .page-offres .tab-inscription .row .options .texte {
        font-size: 16px;
        line-height: 18px; }
        body.page-template-page_offres .page-offres .tab-inscription .row .options .texte strong {
          display: block; }
      body.page-template-page_offres .page-offres .tab-inscription .row .options .prix span {
        display: block;
        background-color: #2E9E71;
        color: white;
        text-align: center;
        padding: 6px 0;
        font-size: 16px; }
        body.page-template-page_offres .page-offres .tab-inscription .row .options .prix span strong {
          font-size: 24px; }

body.page-template-page_offres .page-offres-mobile {
  background: white; }
  body.page-template-page_offres .page-offres-mobile .offre {
    background: url("img/page_bckgrd.jpg") center 0;
    border-bottom: solid 20px white; }
    body.page-template-page_offres .page-offres-mobile .offre .titre {
      position: relative;
      background: url(img/temoignages_bckgrd.jpg) center 0 no-repeat;
      color: white;
      padding: 10px 0; }
      body.page-template-page_offres .page-offres-mobile .offre .titre .background {
        mix-blend-mode: multiply;
        -webkit-mix-blend-mode: multiply;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0; }
      body.page-template-page_offres .page-offres-mobile .offre .titre .nom {
        font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
        font-weight: bold;
        font-size: 45px;
        position: relative;
        padding-left: 50px;
        padding-right: 0;
        margin-top: 20px; }
      body.page-template-page_offres .page-offres-mobile .offre .titre .nom:before {
        content: '';
        background: url("img/chevron_blanc.png") 0 0 no-repeat;
        background-size: contain;
        height: 50px;
        width: 40px;
        display: block;
        position: absolute;
        top: -12px;
        left: 0; }
      body.page-template-page_offres .page-offres-mobile .offre .titre .tarif {
        position: relative;
        margin-top: 14px;
        padding-left: 0;
        padding-right: 0; }
        body.page-template-page_offres .page-offres-mobile .offre .titre .tarif strong {
          font-size: 45px;
          font-weight: 900; }
        body.page-template-page_offres .page-offres-mobile .offre .titre .tarif em {
          position: absolute;
          top: -10px;
          font-size: 25px;
          font-style: normal;
          font-weight: 300; }
    body.page-template-page_offres .page-offres-mobile .offre .intro ul {
      list-style: none;
      padding: 15px 0;
      margin: 0; }
      body.page-template-page_offres .page-offres-mobile .offre .intro ul li {
        background: url(img/true_vert_0.png) 0 6px no-repeat;
        background-size: 20px;
        padding: 6px 0;
        padding-left: 25px; }
        body.page-template-page_offres .page-offres-mobile .offre .intro ul li em {
          font-weight: 700;
          font-style: normal;
          color: #2E9E71; }
        body.page-template-page_offres .page-offres-mobile .offre .intro ul li strong {
          font-weight: 900;
          text-transform: uppercase;
          color: #2E9E71; }
      body.page-template-page_offres .page-offres-mobile .offre .intro ul li.strong {
        background-position: 6px 6px;
        border-right: 2px solid #034E42;
        border-left: 2px solid #034E42;
        padding: 6px 6px 6px 25px; }
      body.page-template-page_offres .page-offres-mobile .offre .intro ul li.strong.first {
        border-top: 2px solid #034E42; }
      body.page-template-page_offres .page-offres-mobile .offre .intro ul li.strong.last {
        border-bottom: 2px solid #034E42; }
    body.page-template-page_offres .page-offres-mobile .offre .intro ul.intro-1 li {
      background-image: url(img/true_vert_1.png); }
      body.page-template-page_offres .page-offres-mobile .offre .intro ul.intro-1 li em {
        color: #077F6C; }
      body.page-template-page_offres .page-offres-mobile .offre .intro ul.intro-1 li strong {
        color: #077F6C; }
    body.page-template-page_offres .page-offres-mobile .offre .intro ul.intro-2 li {
      background-image: url(img/true_vert_2.png); }
      body.page-template-page_offres .page-offres-mobile .offre .intro ul.intro-2 li em {
        color: #034E42; }
      body.page-template-page_offres .page-offres-mobile .offre .intro ul.intro-2 li strong {
        color: #034E42; }
    body.page-template-page_offres .page-offres-mobile .offre .options ul {
      list-style: none;
      padding: 15px 0; }
      body.page-template-page_offres .page-offres-mobile .offre .options ul li {
        padding-left: 25px;
        margin: 6px 0; }
        body.page-template-page_offres .page-offres-mobile .offre .options ul li em {
          font-weight: 700;
          font-style: normal;
          color: #2E9E71; }
        body.page-template-page_offres .page-offres-mobile .offre .options ul li strong {
          font-weight: 900;
          text-transform: uppercase; }
      body.page-template-page_offres .page-offres-mobile .offre .options ul li:first-child {
        font-weight: bold; }
    body.page-template-page_offres .page-offres-mobile .offre .options ul.option-1 li em {
      color: #077F6C; }
    body.page-template-page_offres .page-offres-mobile .offre .options ul.option-2 li em {
      color: #034E42; }
    body.page-template-page_offres .page-offres-mobile .offre .bas {
      position: relative;
      color: white; }
      body.page-template-page_offres .page-offres-mobile .offre .bas > div {
        position: relative; }
      body.page-template-page_offres .page-offres-mobile .offre .bas .background {
        mix-blend-mode: multiply;
        -webkit-mix-blend-mode: multiply;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0; }
      body.page-template-page_offres .page-offres-mobile .offre .bas .nom {
        padding: 0 5px; }
        body.page-template-page_offres .page-offres-mobile .offre .bas .nom a {
          color: white;
          padding: 8px 0;
          padding-left: 46px;
          display: block; }
          body.page-template-page_offres .page-offres-mobile .offre .bas .nom a strong {
            font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
            font-weight: bold;
            font-size: 25px; }
      body.page-template-page_offres .page-offres-mobile .offre .bas .nom:before {
        content: '';
        background: url("img/chevron_blanc.png") 0 0 no-repeat;
        background-size: contain;
        height: 22px;
        width: 22px;
        display: block;
        position: absolute;
        left: 20px;
        top: 10px; }
      body.page-template-page_offres .page-offres-mobile .offre .bas .inscription {
        background: url("img/fleche_blanc.png") right 10px top 5px no-repeat;
        background-size: 30px;
        background-color: #FF4E00; }
        body.page-template-page_offres .page-offres-mobile .offre .bas .inscription a {
          color: white;
          font-size: 14px;
          font-weight: 700;
          padding: 8px 0;
          display: block;
          cursor: pointer; }

body.page-template-page_offres .tab-options {
  background-color: white;
  padding-top: 20px; }
  @media only screen and (max-width: 990px) {
    body.page-template-page_offres .tab-options {
      padding-top: 0; } }
  body.page-template-page_offres .tab-options .option {
    background: url("img/down_vert.png") 20px 0 no-repeat;
    background-size: 50px;
    padding-top: 60px;
    position: relative; }
    @media only screen and (max-width: 990px) {
      body.page-template-page_offres .tab-options .option {
        background: transparent;
        padding-top: 0px; } }
    body.page-template-page_offres .tab-options .option .content {
      border-right: solid 8px #72CFAA;
      padding-bottom: 50px; }
      @media only screen and (max-width: 990px) {
        body.page-template-page_offres .tab-options .option .content {
          padding-left: 90px;
          border: none;
          border-bottom: solid 8px #72CFAA;
          padding-bottom: 30px;
          margin-bottom: 30px; } }
      body.page-template-page_offres .tab-options .option .content .titre {
        color: #72CFAA;
        font-size: 35px;
        font-weight: bold;
        line-height: 36px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: solid 4px #034E42;
        width: fit-content; }
        body.page-template-page_offres .tab-options .option .content .titre strong {
          font-size: 40px;
          display: block;
          font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
          font-weight: bold; }
          @media only screen and (max-width: 990px) {
            body.page-template-page_offres .tab-options .option .content .titre strong {
              display: inline; } }
      body.page-template-page_offres .tab-options .option .content .texte {
        width: 90%; }
      body.page-template-page_offres .tab-options .option .content .prix {
        margin-top: 20px;
        color: #034E42;
        font-size: 40px;
        font-weight: bold; }
  body.page-template-page_offres .tab-options .option.s-track:after,
  body.page-template-page_offres .tab-options .option.programme:after,
  body.page-template-page_offres .tab-options .option.test:after {
    content: '';
    position: absolute;
    width: 120px;
    height: 80px;
    right: 60px;
    top: 48px;
    display: block;
    background: url("img/3_mois.png") 0 0 no-repeat;
    background-size: contain; }
    @media only screen and (max-width: 990px) {
      body.page-template-page_offres .tab-options .option.s-track:after,
      body.page-template-page_offres .tab-options .option.programme:after,
      body.page-template-page_offres .tab-options .option.test:after {
        left: 15px;
        top: 0;
        height: 80px; } }
  body.page-template-page_offres .tab-options .option.programme:after {
    width: 100px;
    height: 80px;
    right: 45px;
    top: 48px;
    background: url("img/chrono.png") 0 0 no-repeat;
    background-size: contain; }
    @media only screen and (max-width: 990px) {
      body.page-template-page_offres .tab-options .option.programme:after {
        top: 0; } }
  body.page-template-page_offres .tab-options .option.test:after {
    width: 100px;
    height: 80px;
    right: 20px;
    top: 48px;
    background: url("img/test.png") 0 0 no-repeat;
    background-size: contain; }
    @media only screen and (max-width: 990px) {
      body.page-template-page_offres .tab-options .option.test:after {
        top: 0; } }
  body.page-template-page_offres .tab-options .row > .option:last-child .content {
    border-right: none;
    border-bottom: none; }
  @media only screen and (max-width: 1400px) {
    body.page-template-page_offres .tab-options .option .content .titre {
      font-size: 28px;
      line-height: 36px; }
      body.page-template-page_offres .tab-options .option .content .titre strong {
        font-size: 32px; }
    body.page-template-page_offres .tab-options .option .content .texte {
      width: 88%; }
    body.page-template-page_offres .tab-options .option .content .prix {
      font-size: 40px; }
    body.page-template-page_offres .tab-options .option.s-track:after {
      width: 90px; }
    body.page-template-page_offres .tab-options .option.programme:after {
      width: 75px; }
    body.page-template-page_offres .tab-options .option.test:after {
      width: 80px; } }
  @media only screen and (max-width: 1200px) {
    body.page-template-page_offres .tab-options .option .content .titre {
      font-size: 24px;
      line-height: 30px; }
      body.page-template-page_offres .tab-options .option .content .titre strong {
        font-size: 28px; }
    body.page-template-page_offres .tab-options .option .content .texte {
      width: 90%;
      font-size: 14px; }
    body.page-template-page_offres .tab-options .option .content .prix {
      font-size: 40px; }
    body.page-template-page_offres .tab-options .option.s-track:after {
      width: 80px; }
    body.page-template-page_offres .tab-options .option.programme:after {
      width: 65px; }
    body.page-template-page_offres .tab-options .option.test:after {
      width: 70px; } }

/* BLOC PERSONAL TRAINING */
#personal {
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
  background: url("img/page_bckgrd.jpg") center 0; }
  #personal .content {
    position: relative; }
    @media only screen and (max-width: 990px) {
      #personal .content {
        padding-bottom: 140px; } }
    @media only screen and (max-width: 768px) {
      #personal .content {
        padding-bottom: 100px; } }
    #personal .content h2, #personal .content .h2 {
      font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
      font-weight: bold;
      font-size: 55px;
      color: #FF4E00;
      font-weight: bold;
      border-bottom: solid 4px #FF4E00;
      width: fit-content; }
      @media only screen and (max-width: 1400px) {
        #personal .content h2, #personal .content .h2 {
          font-size: 50px; } }
      @media only screen and (max-width: 1200px) {
        #personal .content h2, #personal .content .h2 {
          font-size: 42px; } }
      @media only screen and (max-width: 990px) {
        #personal .content h2, #personal .content .h2 {
          font-size: 45px;
          margin-left: 50px; } }
    #personal .content h3, #personal .content .h3 {
      margin-top: 30px;
      font-size: 14px; }
  #personal .content:before {
    height: 50px;
    width: 40px;
    display: block;
    background: url("img/chevron_orange.png") 0 0 no-repeat;
    background-size: contain;
    content: '';
    position: absolute;
    top: 15px;
    left: -50px; }
    @media only screen and (max-width: 990px) {
      #personal .content:before {
        left: 0px; } }
  #personal .content:after {
    height: 160px;
    width: 130px;
    display: block;
    background: url("img/fleche_orange.png") 0 0 no-repeat;
    background-size: contain;
    content: '';
    position: absolute;
    bottom: 0;
    right: -200px; }
    @media only screen and (max-width: 990px) {
      #personal .content:after {
        right: 0px;
        bottom: -50px; } }
    @media only screen and (max-width: 576px) {
      #personal .content:after {
        display: none; } }
  #personal .img {
    width: 29%;
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px; }
    @media only screen and (max-width: 768px) {
      #personal .img {
        width: 230px; } }

body.page-template-page_offres #personal {
  border-top: solid 20px #FF4E00; }

@media only screen and (max-width: 990px) {
  body.page-template-page_offres .small,
  body.page-template-page_offres .indoorphine {
    padding-left: 35px;
    padding-right: 35px; } }

/* BLOC FOOTER PAGE INTERIEURE */
.page-block-footer .indoorphine {
  border-top: solid 20px #077F6C;
  background: url(img/footer_indoorphine_bckgrd.jpg) 0 bottom;
  position: relative;
  padding-bottom: 20px; }
  @media only screen and (max-width: 990px) {
    .page-block-footer .indoorphine {
      width: calc(100% - 20px); } }
  .page-block-footer .indoorphine .titre {
    margin-left: 18%;
    margin-top: 10px;
    width: 50%; }
    @media only screen and (max-width: 990px) {
      .page-block-footer .indoorphine .titre {
        margin-left: 0;
        padding-left: 15px;
        padding-right: 15px; } }
  .page-block-footer .indoorphine .content {
    margin-left: 45%; }
    @media only screen and (max-width: 990px) {
      .page-block-footer .indoorphine .content {
        margin-left: 0;
        padding-bottom: 40px;
        padding-left: 15px;
        padding-right: 15px; } }
    .page-block-footer .indoorphine .content h2, .page-block-footer .indoorphine .content .h2 {
      font-weight: 900;
      color: #034E42;
      clear: both;
      font-size: 22px; }
    .page-block-footer .indoorphine .content h3, .page-block-footer .indoorphine .content .h3 {
      font-weight: 500;
      font-size: 14px; }
    .page-block-footer .indoorphine .content .lien {
      font-size: 25px;
      text-align: right;
      text-transform: uppercase;
      margin-top: 40px; }
  .page-block-footer .indoorphine .img {
    content: '';
    width: 40%;
    position: absolute;
    bottom: 0;
    left: 40px; }

.page-block-footer .small {
  background: url(img/footer_small_bckgrd.jpg) 0 bottom;
  background-size: cover;
  padding-top: 30px;
  padding-bottom: 20px;
  position: relative; }
  @media only screen and (max-width: 990px) {
    .page-block-footer .small {
      padding-bottom: 20px;
      width: calc(100% - 20px); } }
  .page-block-footer .small * {
    color: white; }
  .page-block-footer .small a {
    font-size: 30px;
    font-weight: 300; }
    .page-block-footer .small a h2, .page-block-footer .small a .h2 {
      font-family: 'Lovelo Line Bold','Comfortaa', sans-serif;
      font-weight: 700;
      font-size: 50px;
      margin-bottom: 0; }
      @media only screen and (max-width: 990px) {
        .page-block-footer .small a h2, .page-block-footer .small a .h2 {
          padding-left: 15px;
          padding-right: 15px; } }
    .page-block-footer .small a h3, .page-block-footer .small a .h3 {
      margin: 20px 0;
      font-size: 14px;
      width: 80%; }
      @media only screen and (max-width: 990px) {
        .page-block-footer .small a h3, .page-block-footer .small a .h3 {
          width: 100%;
          padding-left: 15px;
          padding-right: 15px; } }
    .page-block-footer .small a .lien {
      font-size: 25px;
      text-transform: uppercase;
      margin-top: 40px; }
      @media only screen and (max-width: 990px) {
        .page-block-footer .small a .lien {
          padding-left: 15px;
          padding-right: 15px; } }
  .page-block-footer .small .appli {
    width: 500px;
    position: absolute;
    left: 50px;
    bottom: 20px; }
    .page-block-footer .small .appli .img {
      width: 100%; }
    @media only screen and (max-width: 990px) {
      .page-block-footer .small .appli {
        position: relative;
        bottom: unset;
        left: unset;
        width: 80%;
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (max-width: 768px) {
      .page-block-footer .small .appli {
        width: 100%; } }
    .page-block-footer .small .appli a {
      display: block;
      height: 100%; }

@media only screen and (max-width: 990px) {
  body.page-template-page_interieure .site-container #content {
    background: url("img/page_header_bckgrd.jpg") center 0 no-repeat;
    padding-top: 36px; }
    body.page-template-page_interieure .site-container #content .page-block .block-page {
      padding: 10px; }
      body.page-template-page_interieure .site-container #content .page-block .block-page .content h1, body.page-template-page_interieure .site-container #content .page-block .block-page .content .h1 {
        text-align: center !important;
        font-size: 25px;
        padding-bottom: 10px;
        padding-top: 10px; }
        body.page-template-page_interieure .site-container #content .page-block .block-page .content h1 strong, body.page-template-page_interieure .site-container #content .page-block .block-page .content .h1 strong {
          font-size: 45px; }
      body.page-template-page_interieure .site-container #content .page-block .block-page .content h1:after, body.page-template-page_interieure .site-container #content .page-block .block-page .content .h1:after {
        height: 3px;
        width: 100%; }
      body.page-template-page_interieure .site-container #content .page-block .block-page .content h2, body.page-template-page_interieure .site-container #content .page-block .block-page .content .h2 {
        font-size: 15px;
        right: 0;
        padding-left: 50px;
        padding-right: 0;
        width: 100%;
        background-size: 40px; }
      body.page-template-page_interieure .site-container #content .page-block .block-page .content h3, body.page-template-page_interieure .site-container #content .page-block .block-page .content .h3 {
        font-size: 15px;
        text-align: justify; }
      body.page-template-page_interieure .site-container #content .page-block .block-page .visuel img {
        width: 100%; }
    body.page-template-page_interieure .site-container #content .page-block .cta {
      margin-top: 30px; }
      body.page-template-page_interieure .site-container #content .page-block .cta a {
        background-size: 30px;
        padding: 0 0 0 40px;
        font-size: 22px; }
        body.page-template-page_interieure .site-container #content .page-block .cta a .type {
          font-size: 20px; }
        body.page-template-page_interieure .site-container #content .page-block .cta a strong {
          font-size: 18px; }
        body.page-template-page_interieure .site-container #content .page-block .cta a strong:after {
          height: 36px;
          width: 36px;
          top: -1px;
          right: -50px; }
    body.page-template-page_interieure .site-container #content .page-block .block-page.texte-visuel .content {
      padding-right: calc(var(--bs-gutter-x) * .5) !important; }
    body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content {
      padding-left: calc(var(--bs-gutter-x) * .5) !important; }
      body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content h1, body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content .h1 {
        text-align: justify; }
      body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content h1:after, body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content .h1:after {
        left: 0; }
      body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content h2, body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content .h2 {
        background-position: 0px center;
        padding-left: 50px;
        padding-right: 0;
        left: 0; }
      body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content h3, body.page-template-page_interieure .site-container #content .page-block .block-page.visuel-texte .content .h3 {
        text-align: left; } }

/* ANIMATION */
.from_left {
  opacity: 0;
  transition: 0.5s;
  left: -20px; }

.from_bottom {
  transition: 2s;
  margin-top: 20px !important; }

.appear {
  opacity: 1;
  left: 0;
  top: 0; }

.blockheader {
  position: relative; }

.animation {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.img-effect {
  width: 300px;
  height: 300px;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none; }

.img-effect img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; }
